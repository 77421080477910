.modal-content {
    width: 100%;
    max-width: 400px; /* Adjust modal width */
  }
  
  .questionnaire-list {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between checkboxes */
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    border-radius: 5px;
  }
  
.modal_questionnaire {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;
  }
  
  .modal_questionnaire.hidden {
    display: none;
  }
  
  .modal-content_questionnaire {
    background: #ffffff;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .modal-header_questionnaire {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .modal-title_questionnaire {
    font-size: 1.3rem;
    font-weight: bold;
    color: #041c32;
  }
  
  .close-btn_questionnaire {
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #041c32;
  }
  
  .close-btn_questionnaire:hover {
    color: #d9534f;
  }
  
  .modal-body_questionnaire {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .questionnaire-item {
    display: flex;
    align-items: center;
  }
  
  .questionnaire-item input {
    margin-right: 8px;
  }
  
  .modal-footer_questionnaire {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 15px;
  }
  
  .btn_cancel {
    background: #ccc;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn_submit {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn_submit:hover {
    background: #0056b3;
  }
  