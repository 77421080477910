@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.form_div_two {
  /* border: 1px solid red; */

  width: 100%;

  /* overflow: hidden; */
}
.login-box {
  background-color: rgba(1, 20, 52, 0.99);
  padding: 30px;
  border-radius: 5px;
  width: 450px;
  text-align: center;
}

.login-box h2 {
  color: white;
  margin-bottom: 30px;
}

.login-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(217, 217, 217, 0.3);
  border-radius: 10px;
  padding: 13px 20px;
  margin-bottom: 25px;
  cursor: pointer;
}

.login-option img {
  width: 40px;
  height: 40px;
}

.login-option span {
  color: white;
  font-size: 18px;
  flex-grow: 1;
  text-align: left;
  padding-left: 15px;
}

.login-option input {
  width: 20px;
  height: 20px;
  accent-color: #bc9c22;
}

.login-button {
  background-color: #bc9c22;
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-footer {
  color: white;
  font-size: 14px;
}

.login-footer a {
  color: #bc9c22;
  text-decoration: none;
  margin-left: 5px;
  background-color: white;
  padding: 10px;
  border-radius: 40px;
  padding-left: 22px;
  padding-right: 22px;
}

@media (max-width: 400px) {
  .login-box {
    width: 90%;
    padding: 20px;
  }

  .login-option img {
    width: 30px;
    height: 30px;
  }

  .login-option span {
    font-size: 16px;
  }

  .login-button {
    padding: 10px;
    font-size: 14px;
  }
}

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
}

h2 {
  color: rgba(1, 20, 52, 1);
  margin-bottom: 60px;
  /* margin-bottom: '20%'; */
  font-size: 28px;
  font-weight: bold;
}

.input-container {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
}
.errorMessage {
  text-align: left;
  margin-left: 2px;
}
label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: rgba(1, 20, 52, 1);
  font-size: 16px;
  text-align: left;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.password-container {
  position: relative;
}

.password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.password-icon_two {
  position: absolute;
  right: 10px;
  top: 43%;
  transform: translateY(-50%);
  cursor: pointer;
}
.forgot-password {
  display: block;
  margin-top: 8px;
  color: rgba(188, 156, 34, 1);
  font-size: 14px;
  text-align: right;
  text-decoration: none;
}

/* .or-login-with {
  margin-top: 30px;
  text-align: center;
  width: 100%;
} */

.or-login-with p {
  color: rgba(188, 156, 34, 1);
  margin-bottom: 20px;
  font-size: 14px;
  margin-top: 30px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icons img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 768px) {
  .login-container-LoginScreen {
    flex-direction: column;
  }

  .right-section h2 {
    font-size: 24px;
  }

  .login-button {
    padding: 10px;
    font-size: 14px;
  }
}

.AppleLogo-div {
  padding: 10px;
  border: 1px solid rgba(1, 20, 52, 1);
  border-radius: 60px;
}

.Register-txt {
  /* font-family: Urbanist; */
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  color: rgba(188, 156, 34, 1);
  text-align: center;
  border-radius: 40px;
  border: 1px solid rgba(188, 156, 34, 1);
  cursor: pointer;
  width: 100px;
}

.dont-have-account {
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 50px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(1, 20, 52, 1);
  display: flex;
  margin-bottom: 30px;
}

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
}

/* Container for the sign-up form */
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0;
  background-color: #fff;
  width: 50%;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Scrollable when content overflows */
  /* border: 1px solid red; */
}

.signup-container h2 {
  color: #273f5b;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Styles for the left side (fixed) */
/*  */
.left-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Form styling */
.signup-form {
  width: 100%;
  /* max-width: 500px; */
}

.input-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}

.input-row_two {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 5px;
}
.input-container {
  width: 100%;
}

.input-container label {
  display: block;
  /* margin-bottom: 8px; */
  color: #273f5b;
  font-size: 16px;
  /* border: 1px solid red; */
}

.input-wrapper {
  position: relative;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.password-input {
  padding-right: 40px;
}

/* Terms and Conditions */
.terms {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.terms label {
  margin-left: 10px;
  color: #273f5b;
  font-size: 14px;
}

.terms span {
  color: #f5a623;
  cursor: pointer;
}

/* Sign Up Button */

/* Login text */
.login-text {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #273f5b;
}

.login-text a {
  color: #f5a623;
  text-decoration: none;
  font-weight: bold;
}

/* Responsive design */
@media (max-width: 768px) {
  .input-row {
    flex-direction: column;
  }
}
.input-row_two_ {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}
/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #f4f5f7;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.sidebar-header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.sidebar-menu {
  list-style: none;
  width: 100%;
}

.menu-item {
  padding: 15px 20px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
}

.menu-item.active,
.menu-item:hover {
  background-color: #fff;
  color: #f5a623;
  border-left: 4px solid #f5a623;
}

.icon-dashboard,
.icon-appointments,
.icon-financial,
.icon-messages,
.icon-profile,
.icon-settings,
.icon-support,
.icon-logout {
  margin-right: 10px;
}

.sidebar-footer {
  margin-top: auto;
  padding: 20px;
  color: #f53649;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Navbar */
.navbar {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-bar {
  position: relative;
}

.search-bar input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.icon-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.navbar-icons {
  display: flex;
  align-items: center;
}

.icon-notifications {
  margin-right: 20px;
}

.user-profile {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Dashboard */
.dashboard-container {
  display: flex;
  background-color: white;

  height: 100vh;
  overflow: hidden;
}
.dashboard-container Sidebar {
  width: 250px; /* Adjust based on your design */
  height: 100vh;
  position: fixed; /* Make sure the sidebar is fixed */
  top: 0;
  left: 0;
  background-color: #f8f9fa; /* Or any color you want */
  z-index: 1; /* Ensure it stays on top of content */
}
.dashboard-content {
  flex-grow: 1;
  width: calc(100% - 250px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* padding: 20px; */
}
.dashboard-content Navbar {
  width: 100%;
  background-color: #fff;
  position: sticky; /* Make sure it stays at the top while scrolling */
  top: 0;
  z-index: 2; /* Ensure it stays above content */
}
.dashboard-cards {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

.dashboard-card {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dashboard-card.green {
  border-top: 5px solid #4caf50;
}

.dashboard-card.blue {
  border-top: 5px solid #2196f3;
}

.dashboard-card.pink {
  border-top: 5px solid #e91e63;
}

.dashboard-card h3 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.card-data-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dashboard-actions {
  display: flex;
  justify-content: start;
  gap: 10px;
}

.action-btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.action-btn.primary {
  background-color: #273f5b;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-cards {
    flex-direction: column;
    gap: 10px;
  }

  .sidebar {
    width: 300px;
  }

  .menu-item {
    padding: 10px 15px;
  }

  .dashboard-actions {
    flex-direction: column;
  }
}

.appointments-table-container {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.appointments-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Arial", sans-serif;
}

.appointments-table th,
.appointments-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.appointments-table th {
  background-color: #f7f7f7;
  color: #333;
}

.appointments-table tbody tr:hover {
  background-color: #f1f1f1;
}

.client-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.view-button {
  background-color: #1a1a1a;
  color: white;
  border: none;
  /* padding: 8px 16px; */
  border-radius: 5px;
  cursor: pointer;
}

.view-button:hover {
  background-color: #333;
}

.clinic-providers-container {
  width: 100%;

  background-color: #fff;
  border-radius: 10px;
}

.headerheader {
  text-align: left;
  margin-bottom: 20px;
  background-color: rgba(1, 20, 52, 1);
  color: #fff;
  padding: 20px;
  width: 500px;
  top: 201px;
  left: 588px;
  gap: 0px;
  border-radius: 10px;
}
.clicnic-provider-paragraf {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 25.5px;
  letter-spacing: 0.22588224709033966px;
  text-align: left;
}
.CLINIC-PROVIDERS-txt {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #f7f7f7;
  color: #001f54;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  background-color: #001f54;
  color: white;
}

.tab-button:hover {
  background-color: #001f54;
  color: white;
}

.add-provider-button {
  background-color: rgba(188, 156, 34, 1);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  float: right;
}

.add-provider-button:hover {
  background-color: rgba(188, 156, 34, 1);
}
/* UserTable.css */

.table-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead {
  background-color: #f8f9fa;
  color: #495057;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

th {
  font-weight: bold;
}

tr:hover {
  background-color: #f1f3f5;
}

.view-button {
  padding: 5px;
  background-color: #0d1b4c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.view-button:hover {
  background-color: #001b87;
}

.results-info {
  font-size: 14px;
  color: #495057;
  text-align: left;
  margin-top: 30px;
}
.actions-button {
  padding: 5px 15px;
  background-color: #0d1b4c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tab-buttons {
  display: flex;
  margin-bottom: 20px;
}
.tab-button {
  padding: 10px 20px;
  border: none;
  background-color: #f8f9fa;
  color: #495057;
  cursor: pointer;
  /* margin-right: 10px; */
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.tab-button.active {
  background-color: #0d1b4c;
  color: white;
}
.tab-buttontwo.active {
  background-color: #0d1b4c;
  color: white;
}
.tab-buttonstwo {
  display: flex;
  margin-bottom: 20px;
}
.tab-buttontwo {
  padding: 10px 20px;
  border: none;
  background-color: #f8f9fa;
  color: #495057;
  cursor: pointer;
  /* margin-right: 10px; */
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  width: 50%;
}
.tab-buttontwoo.active {
  background-color: #0d1b4c;
  color: white;
}
.tab-buttontwoo {
  padding: 10px 20px;
  border: none;
  background-color: #f8f9fa;
  color: #495057;
  cursor: pointer;
  /* margin-right: 10px; */
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  /* width: 50%; */
}

.app {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-controls {
  display: flex;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
}

.summary-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card {
  width: 30%;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
}

.green {
  background-color: #8fd19e;
}

.blue {
  background-color: #91dafc;
}

.purple {
  background-color: #c8b6f9;
}

.card-value {
  font-size: 24px;
  margin-top: 10px;
}

.earnings-graph {
  margin-bottom: 20px;
}

.graph {
  height: 200px;
  background-color: #f4f4f4;
  border-radius: 8px;
  text-align: center;
  line-height: 200px;
  color: #999;
}

.payments-table {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.status.success {
  color: green;
}

.status.held {
  color: orange;
}
.schedule-app {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.schedule-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.schedule-title {
  font-size: 24px;
  color: #011434;
}

.schedule-controls {
  display: flex;
  gap: 10px;
}

.schedule-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.schedule-main-content {
  display: flex;
  gap: 20px;
}

.schedule-left-panel {
  width: 100%;
}

.schedule-right-panel {
  width: 30%;
}

.schedule-date-picker {
  margin-bottom: 20px;
}

.schedule-week-days {
  display: flex;
  /* justify-content: space-between; */
  /* border: 1px solid red; */
  width: 100%;
  align-items: center;
}

.schedule-day {
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.schedule-day-number {
  font-weight: bold;
  display: block;
}

.schedule-day-name {
  font-size: 12px;
}

.schedule-day-selected {
  background-color: rgba(188, 156, 34, 1);
  border-radius: 5px;
  color: white;
}

.schedule-appointment-list {
  margin-top: 20px;
}

.schedule-section-title {
  margin: 20px 0 10px;
  color: #666;
}

.schedule-appointment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.schedule-time {
  width: 80px;
  font-weight: bold;
}

.schedule-details {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.schedule-avatar {
  border-radius: 50%;
}

.schedule-info {
  flex-grow: 1;
}

.schedule-name {
  font-weight: bold;
  color: #333;
}

.schedule-gender-age {
  font-size: 12px;
  color: #888;
}

.schedule-type {
  color: #555;
}

.schedule-add-button {
  padding: 5px 10px;
  background-color: rgba(188, 156, 34, 1);
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.schedule-no-appointments {
  color: #888;
}

.schedule-calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.schedule-calendar-nav {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.schedule-calendar-title {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #333;
}

.schedule-calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.schedule-calendar-day {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.schedule-calendar-day-selected {
  background-color: rgba(188, 156, 34, 1);
  color: white;
  border-radius: 60%;
}

.Profile_txtt {
  /* font-family: Urbanist; */
  font-size: 28px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  margin-left: 30px;
}
.flex-row {
  display: flex;
  align-items: center;
}

.clinicnametxtt {
  /* font-family: Urbanist; */
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #011434;
}

.clicnic-name {
  /* font-family: Urbanist; */
  font-size: 34px;
  font-weight: 500;
  text-align: left;
}
.Contact_Information_hhh {
  text-align: left;
  margin-top: 10px;
  color: #011434;
  font-size: 17px;
  font-weight: 400;
}
.space-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 90%;
  /* border: 1px solid red; */
}
.Email_Address_txt {
  /* font-family: Urbanist; */
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #011434;
  opacity: 0.5;
}

.emaill {
  /* font-family: Urbanist; */
  font-size: 25px;
  font-weight: 500;
  /* letter-spacing: -0.30000001192092896px; */
  color: #000000;
  text-align: left;
}
.cancelButton {
  width: 250px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #d9d9d9;
  border-radius: 6px;
  color: #011434;
  margin-right: 20px;
}
.savebuttonn {
  width: 250px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #011434;
  border-radius: 6px;
  color: #ffffff;
  margin-left: 20px;
  border: none;
}

.General_Settings {
  background-color: #011434;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  margin-right: 40px;
}
.Clinical_Settings {
  border: 1px solid #011434;
  padding: 10px;
  border-radius: 10px;
  margin-right: 40px;
}

.HelpSupport_div {
  background-color: #bc9c22;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-self: center;
  justify-self: center;
  width: 50%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
}
.Contact_Us_txt {
  font-size: 25px;
  font-weight: 700;
  line-height: 50px;
  /* text-align: left; */
  color: #fff;
  margin-bottom: 20px;
}

.Financial_Billing_div {
  width: 50%;
  border-right: 2px solid #011434;
  padding-top: 20px;
}

.navbar-container {
  background-color: white;
  height: 75px;
  border-bottom: 0.5px solid rgba(1, 20, 52, 1);
  display: flex;
  align-items: center;
  padding: 20px;
  padding-right: 20px;
  justify-content: space-between;
  width: 100%;
  position: sticky; /* Make the navbar sticky */
  top: 0; /* Stick to the top of the page */
  z-index: 10;
  margin-bottom: 10px;
}

.Billing-txt {
  font-size: 30px;
  font-weight: 600;
  text-align: left;
}
.months_summary {
  /* font-family: Urbanist; */
  font-size: 25px;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  color: #011434;
}

.billed_box {
  height: 20px;
  width: 40px;
  border: 3px solid red;
  background-color: #d9d9d9;
  margin-right: 5px;
}

/* CustomChart.css */
.chart-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.recharts-tooltip-label {
  font-size: 14px;
  font-weight: 500;
}

.recharts-tooltip-item {
  color: #2b2d42;
  font-size: 14px;
  font-weight: bold;
}
.Billing_boxx {
  background-color: #d9d9d9;
  padding: 15px;
}

.Average_units {
  /* font-family: Urbanist; */
  font-size: 12px;
  font-weight: 500;

  text-align: center;
}
.action-btn {
  background-color: white;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
}

.action-btn.active {
  background-color: #011434;
  color: white;
}

.action-btn:hover {
  background-color: lightgray;
}

.clinics-table-container {
  padding: 20px;
}

.clinics-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.clinics-table thead th {
  background-color: #f5f5f5;
  text-align: left;
  padding: 10px;
  font-weight: bold;
  border-bottom: 2px solid #dddddd;
}

.clinics-table tbody td {
  padding: 15px;
  border-bottom: 1px solid #dddddd;
}

.clinic-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.view-button {
  background-color: #011434; /* Navy blue */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.view-button:hover {
  background-color: #000066; /* Darker navy blue */
}
.notification-list {
  width: 100%;
  font-family: Arial, sans-serif;
  padding: 20px;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #dddddd;
}

.notification-label {
  font-size: 18px;
  color: #333333;
}

.notification-count {
  display: flex;
  justify-content: center;
  align-items: center;
}

.count-circle {
  background-color: #d4a017; /* Golden yellow */
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

/* src/App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.chat-container {
  display: flex;
  height: 88vh;
  /* border: 1px solid red; */
  width: 100%;
}

.messagesidebar {
  width: 30%;
  /* background-color: #f9f9f9; */
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.chatHeader {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatHeader-title {
  color: #fff;
  margin: 0;
  background-color: #011434;
  padding: 10px;
  font-size: 30px;
  border-radius: 20px;
}

.add-button {
  background-color: #001845;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-item.active {
  background-color: #e9ecef;
}

.contact-item img {
  border-radius: 50%;
  margin-right: 15px;
}

.contact-info h4 {
  margin: 0;
  font-size: 16px;
}

.contact-info p {
  margin: 0;
  font-size: 12px;
  color: #666;
}

.time {
  margin-left: auto;
  font-size: 12px;
  color: #999;
}

.chat-area {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.chat-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header-left {
  display: flex;
  align-items: center;
}

.chat-header-left img {
  border-radius: 50%;
  margin-right: 15px;
}

.chat-header-info h3 {
  margin: 0;
}

.chat-header-info p {
  margin: 0;
  color: green;
}

.more-options {
  background-color: #011434;
  /* padding: 20px; */
  height: 50px;
  width: 50px;
  align-content: center;
  border-radius: 30px;
  cursor: pointer;
}

.chat-messages {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
}

.chat-bubble {
  max-width: 60%;
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 20px;
  position: relative;
}

.chat-bubble.user {
  background-color: #e6b800;
  margin-left: auto;
}

.chat-bubble p {
  margin: 0;
  font-size: 14px;
}

.chat-bubble .time {
  position: absolute;
  bottom: -20px;
  right: 10px;
  font-size: 10px;
  color: #666;
}

.chat-input {
  display: flex;
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  background-color: #d9d9d9;
}

.chat-input input {
  flex-grow: 1;
  border: none;
  padding: 10px;
  font-size: 14px;
}

.chat-input input:focus {
  outline: none;
}

.send-button {
  background-color: #d9d9d9;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}

.dashborad_plus {
  display: flex;
  position: absolute;
  right: 50px;
  bottom: 100px;
  color: white;
  background-color: #011434;
  font-size: 55px;
  border-radius: 80px;
  height: 50px;
  width: 50px;
  align-content: center;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.Clinical_Settingstwo {
  /* border: 1px solid #011434; */
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  background-color: #bc9c22;
  color: white;
}

/* Container for the tabs */
.tab-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

/* Styling for active and inactive tabs */
.active-tab {
  background-color: #bc9c22;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}

.inactive-tab {
  padding: 10px 20px;
  color: #000;
  cursor: pointer;
  border: 1px solid #c3a66e;
  border-radius: 5px;
  margin-right: 10px;
}

/* Grid container for time slots */
.grid-container_two {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  /* border: 1px solid red; */
}

.grid-container_two_days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 15px;
  /* border: 1px solid red; */
}

/* Time slot styling */
.time-slot {
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #c3a66e;
  border-radius: 5px;
  background-color: white;
  color: #000;
}

.time-slot.selected {
  background-color: #bc9c22;
  color: white;
}

.Patient_Chart_header {
  width: 100%;
  border-bottom: 1px solid #011434;
  padding-bottom: 20px;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  justify-content: space-between;
}
.Patient_Chart_button_div {
  width: 70%;
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patient_Buttons {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  border-radius: 6px;
}

.patient_Main_chart {
  width: 100%;
  display: flex;
}

.patient_left_chart {
  width: 30%;
  border-right: 1px solid #011434;
  display: flex;
  padding: 40px;
  flex-direction: column;
}

.patientchartheader {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #011434;
  justify-content: space-between;
}

.patientchartheaderbutton {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #cccccc;
  width: 110px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.patientchartheaderbuttonactive {
  background-color: #011434;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #cccccc;
  width: 110px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: white;
  border-radius: 6px;
  cursor: pointer;
}

.patient_left {
  width: 30%;
  border-right: 1px solid #011434;
  min-height: 100vh;
  padding: 30px;
}

.patient_right {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.Patient_Chart_txt {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: #011434;
}

.client_id_txt {
  /* font-family: Urbanist; */
  font-size: 15px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 15px;
}

.patient_name_txt {
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.Payment_Insurance_txt {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  margin-top: 5px;
}

.paitient_name_div {
  display: flex;
  border-bottom: 1px solid #011434;
  padding-bottom: 20px;
}

.active_txttt {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.active_txt_div {
  padding: 20px;
  display: flex;
  border-bottom: 1px solid #011434;
}

.space_between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Demographics_txtt {
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.date_of_birth_txtt {
  /* font-family: Urbanist; */
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #011434;
  margin-right: 30px;
}

.paitent_Head_buttons_div {
  padding: 30px;
  border-bottom: 1px solid #011434;
  justify-content: flex-start;
  display: flex;
}

.patient_education_button {
  display: flex;
  align-items: center;
  width: 280px;
  background: #d9d9d94d;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.notes_img_background {
  height: 35px;
  width: 35px;
  background-color: #d9d9d9;
  border-radius: 60px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.last_signed_txt {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  /* border: 1px solid red; */
  text-align: left;
  width: 80%;
}

.Lifestyle_Determinants_main_div {
  width: 100%;
  /* border: 1px solid red; */
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Lifestyle_Determinants_main_div_two {
  width: 100%;
  /* border: 1px solid red; */
  padding: 15px;
  display: flex;
  align-items: center;
}

.Lifestyle_Determinants_chart {
  width: 32%;
  /* width: 290px; */
  height: 385px;
  border-radius: 10px;
  border: 1px solid #01143480;
  padding-top: 15px;
  padding-bottom: 30px;
  padding: 20px;
  background-color: #d9d9d94d;
}

.Lifestyle_Determinants_div {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.smoking_txt {
  /* font-family: Urbanist; */
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
}

.custom-problem-table-container {
  width: 100%;
  margin: 20px auto;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  padding: 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.custom-problem-table-header {
  display: flex;
  justify-content: space-between;
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 4px;
}

.custom-problem-table-header-item {
  flex: 1;
  text-align: left;
  font-weight: bold;
  color: #495057;
  padding: 8px;
}
.custom-problem-table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.custom-problem-table-cell {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media (max-width: 768px) {
  .custom-problem-table-cell {
    width: 100%; /* Stack cells vertically on smaller screens */
    margin-bottom: 10px; /* Add some space between cells */
  }

  .custom-problem-table-row {
    border-bottom: 1px solid #dee2e6;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .custom-problem-table-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-problem-table-cell {
    width: 100%; /* Stack all cells vertically */
    text-align: left;
  }
}

/* .custom-problem-table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  align-items: center;
}

.custom-problem-table-cell {
  flex: 1;
  text-align: left;
  color: #343a40;
  padding: 8px;
} */

.custom-problem-add-button {
  margin-top: 20px;
  background-color: #bc9c22;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100px;
  align-self: flex-end;
  margin-right: 20px;
  display: flex;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.22588224709033966px;
  text-align: center;
}
.custom-problem-add-button_two {
  /* margin-top: 20px; */
  background-color: #bc9c22;
  color: #fff;

  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100px;

  display: flex;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.22588224709033966px;
  margin-left: 40px;
}
.custom-problem-add-button:hover {
  background-color: #c0982f;
}

.medication-table-container {
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  position: relative;
}

.medication-header {
  display: flex;
  justify-content: space-between;
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
  color: #d9d9d980;
}

.medication-header-item {
  color: #011434;
}
.medication-header-item_t {
  color: #011434;
  text-align: center;
}
.medication-empty-state {
  text-align: center;
  margin-top: 50px;
}

.medication-empty-title {
  font-size: 20px;
  color: #343a40;
  font-weight: bold;
}

.medication-empty-description {
  color: #6c757d;
  margin-top: 10px;
}

.medication-header_t {
  display: flex;
  justify-content: space-between;
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 4px;
  color: #d9d9d980;
}

.medication-view-prescriptions-button {
  color: #d4af37;
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.medication-view-prescriptions-button img {
  margin-right: 8px;
}

.medication-view-prescriptions-button:hover {
  color: #c0982f;
}

.view_Prescriptions_txt {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.22588224709033966px;
  text-align: center;
  color: #bc9c22;
  margin-left: 10px;
}
.navbar-containertwo {
  background-color: white;
  height: 75px;
  border-bottom: 1px solid rgba(1, 20, 52, 1);
  display: flex;
  align-items: center;
  padding: 20px;
  padding-right: 20px;
  justify-content: space-between;
  width: 100%;
  position: sticky; /* Make the navbar sticky */
  top: 0; /* Stick to the top of the page */
  z-index: 10; /* Ensure it's above the content */
}

.userTable-container {
  margin: 20px;
  width: 100%;
}

.userTable-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Arial", sans-serif;
}

.userTable-th,
.userTable-td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.userTable-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.userTable-detailsBtn {
  background-color: #011434;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 6px;
}

.userTable-declineBtn {
  background-color: white;
  color: #bc9c22;
  border-radius: 6px;

  border: 1px solid #bc9c22;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 10px;
}

.add_problem_div {
  position: absolute;
  right: 20px;
  top: 230px;
  width: 330px;
  border: 1px solid #01143480;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;

  /* Add max-height and scrolling */
  max-height: 600px; /* Adjust as needed */
  overflow-y: auto;
}

.Add_problem_txtt {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 25.5px;
  letter-spacing: 0.22588224709033966px;
  color: #011434;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 20px; */
}

.Record_Details_txtt {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #011434;
  margin-top: 20px;
  border-bottom: 1px solid #011434;
  padding-bottom: 10px;
}

.problem_div_txtt {
  /* font-family: Urbanist; */
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  margin-top: 10px;
  border-bottom: 1px solid #011434;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.select_option_style {
  padding: 5px;
  border-color: #cccccc;
  border-radius: 5px;
  width: 65%;
}

.code_txt_div {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #011434;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input_num_style {
  border-color: #cccccc;
  width: 65%;
  height: 30px;
}

.action_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 80%;
  align-self: center;
}

textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 8px;
}

.cancel_button,
.save_button {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.cancel_button {
  background-color: #e0e0e0;
  color: #333;
}

.save_button {
  background-color: #001f3f;
  color: white;
}
.care_plan_txtt {
  /* font-family: Urban */
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  margin-bottom: 20px;
}

.Dosage_Instructions_txtt {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
}

.optional_txtt {
  /* font-family: Urbanist; */
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  margin-left: 10px;
}

.Patient_Chart_popup {
  position: absolute;
  right: 20px;
  top: 77px;
  width: 50%;
  height: 90vh; /* Set a fixed height to allow scrolling */
  border: 1px solid #01143480;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* Add some padding to the content */
}

.Add_problem_txtt_two {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 25.5px;
  letter-spacing: 0.22588224709033966px;
  color: #011434;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-bottom: 1px solid #011434;
}

.left_chart_popup {
  display: flex;
  flex-direction: column;
  width: 50%;
  border-right: 1px solid #011434;
  /* padding: 20px; */
  height: 100vh;
}
.right_chart_popup {
  display: flex;
  flex-direction: column;
  width: 50%;
  /* border-right: 1px solid #011434; */
  /* padding: 20px; */
  height: 100vh;
}
.Clinical_Details_txtt {
  /* font-family: Urbanist; */
  font-size: 22px;
  font-weight: 600;
  line-height: 26.4px;
  text-align: left;
  color: #011434;
  margin-bottom: 30px;
}
.MRN_style_div {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #d9d9d980;
}
.Save_buttonnn {
  width: 90px;
  height: 35px;
  top: 570.12px;
  gap: 0px;
  border-radius: 10px;
  background-color: #011434;
  align-items: center;
  justify-content: center;
  display: flex;
  align-self: flex-end;
  margin-right: 30px;
  margin-top: 50px;
  font-size: 15px;
  font-weight: 700;
  line-height: 14.4px;
  color: #ffffff;
}

.Main_Div {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgb(206, 201, 201);
}

.main_div1 {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.main_div_two {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  flex-direction: column;
}
.para_border {
  border: 1px solid #011434;
  border-color: #000;
  height: 40px;
  width: 120px;
  background-color: #011434;
  border-radius: 6px;
  align-content: center;
}
.para_Contact {
  color: white;
  font-size: 12px;
  font-weight: 600;
}
.para_border1 {
  border: 1px solid #011434;
  border-color: #000;
  height: 40px;
  width: 120px;
  border-radius: 6px;
  align-content: center;
}
.Identification {
  color: black;
  font-size: 12px;
  font-weight: 600;
}
.main2_div {
  /* padding-left: 20px; */
  padding-top: 30px;
}
.para_con_details {
  color: #011434;
  font-size: 22px;
  font-weight: 600;
  text-align: start;
}
.para_Mobile {
  font-size: 16px;
  font-weight: 500;
  color: #011434;
  width: 50%;
  text-align: left;
  margin-top: 10px;
  /* border: 1px solid; */
}
.para_Preferred {
  font-size: 18px;
  font-weight: 600;
  color: #011434;
  text-align: left;
}
.para_No {
  font-size: 16px;
  font-weight: 500;
  color: #011434;
  text-align: left;
  padding-top: 35px;
}
.main_div2 {
}

.UI2_Main_Div {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
.UI_main_div {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
.UI_para_Mobile {
  font-size: 16px;
  font-weight: 500;
  color: #011434;
  /* width: 70%; */
  text-align: left;
}
.div_Choose_file {
  height: 91px;
  width: "100%" px;
  border: 1px solid gray;
  border-radius: 5px;
  align-self: center;
  align-content: center;
  justify-content: center;
  display: flex;
}
.image_para_choose {
  align-content: center;
  justify-content: center;
  display: flex;
  background-color: #d9d9d980;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.image {
  height: 12px;
  width: 12px;
  align-self: center;
  margin-right: 5px;
}
.para_choose {
  font-size: 10px;
  font-weight: 500;
  color: #011434;
  text-align: center;
  /* background-color: gray; */
  /* height: 20px; */
  /* width: 50px; */
  align-self: center;
  padding-top: 5px;
  padding-bottom: 5px;
  /* margin-left: 75px;
  margin-right: 75px; */
}

.INTAKE_Chart_popup {
  position: absolute;
  right: 0px;
  top: 157px;
  width: 55.5%;
  border: 1px solid #01143480;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  height: 100vh;
}
.custom-table-container {
  padding: 20px;
  /* background-color: red; */
  border-radius: 8px;
}
.custom-table {
  width: 100%;
  /* border-collapse: collapse; */
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.custom-table-header {
  background-color: rgba(217, 217, 217, 0.5);
  text-align: center;
}

.custom-table-header th {
  padding: 12px;
  font-weight: 600;
  color: #011434;
  border-right: 1px solid #cccccc;
  border-bottom: 0px;
  overflow: hidden;
  text-align: center;
}

.custom-table-row {
  border-radius: 8px;
}
.custom-table-row td {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #011434;
  border: 0px;
}
.custom-select-column {
  width: 5%;
  text-align: center;
  font-size: 17px;
}
.custom-form-name-column {
  width: 30%;
  font-size: 17px;
}
.custom-status-column {
  font-size: 17px;
}
.custom-sent-column {
  font-size: 17px;
}
.custom-completed-column {
  width: 15%;
  font-size: 17px;
}
.custom-actions-column {
  width: 10%;
  text-align: center;
  font-size: 17px;
}
.custom-checkbox {
  width: 20px;
  height: 20px;
  border-width: 3px;
  border-color: red;
}
.custom-edit-button {
  padding: 2px 22px;
  background-color: #011434;
  color: white;
  /* border: none; */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 17px;
  font-weight: 400;
}
.custom-edit-button:hover {
  background-color: #002d6d;
}
.custom-form-name {
  /* font-family: Urbanist; */
  font-size: 18px;
  color: #011434;
  font-weight: 600;
}
.add_problem_div1 {
  position: absolute;
  right: 20px;
  top: 230px;
  width: 330px;
  border: 1px solid #01143480;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 20px;
}

.Add_problem_txtt1 {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 25.5px;
  letter-spacing: 0.22588224709033966px;
  color: #011434;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
}
.div_Draft_txt {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 10px;
}
.Draft_Order_txt {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 600;
  color: #011434;
  text-align: left;
  padding-top: 50px;
  padding-left: 20px;
}

.add_problem_div2 {
  position: absolute;
  right: 20px;
  top: 230px;
  width: 330px;
  border: 1px solid #01143480;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  /* padding: 10px; */
  /* padding-left: 10px; */
  /* padding-right: 20px; */
}
.Record_Details_txt {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 600;
  color: #011434;
  text-align: left;
  padding-top: 20px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  padding-left: 10px;
}
.div_input_Blood_txt {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.Blood_txt {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  /* font-family: Urbanist; */
  color: #011434;
  width: 100px;
}
.dic_Blood_txt {
  align-self: center;
}
.input_txt {
  height: 21px;
}
.input1_txt {
  height: 21px;
  margin-left: 5px;
}
.div_input_Blood_txt1 {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.input_txt1 {
  height: 52px;
  margin-right: 5px;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.Recorded_On_txt {
  /* font-family: Urbanist; */
  font-size: 15px;
  font-weight: 600;
  color: #011434;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}

.Image_notificaton {
  height: 12px;
  width: 25px;
  align-self: center;
}
.button_Canclel {
  height: 35px;
  width: 90px;
  border-radius: 10px;
  border: 1px solid gray;
  align-content: center;
  background-color: rgba(217, 217, 217, 1);
  color: #011434;
  font-size: 12px;
  font-weight: 700;
  /* font-family: Urbanist; */
}
.button_Save {
  height: 35px;
  width: 90px;
  border-radius: 10px;
  border: 1px solid gray;
  align-content: center;
  background-color: #011434;
  color: white;
  font-size: 12px;
  font-weight: 700;
  /* font-family: Urbanist; */
}
.div_button {
  display: flex;
  justify-content: space-evenly;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-top: 20px;
}

.Company_Details_txt {
  /* font-family: Urbanist; */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: left;
  color: #011434;
  margin-bottom: 5px;
}

.user-table-container {
  width: 100%;
  padding: 20px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  text-align: left;
  padding: 10px;
  /* border-bottom: 1px solid #ddd; */
}

.user-table-profile-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.user-table-create-new-button,
.user-table-actions-button {
  padding: 6px 12px;
  background-color: #011434;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.user-table-popup-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 10px;
  margin-top: 5px;
}

.user-table-popup-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-table-popup-menu ul li {
  padding: 8px;
  cursor: pointer;
}

.user-table-popup-menu ul li:hover {
  background-color: #f1f1f1;
}

.user-table-view-notes {
  color: #176bcc;
  cursor: pointer;
  margin-right: 10px;
}

.input-container {
  position: relative;
}

.dropdown-options {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-option:hover {
  background-color: #f1f1f1;
}

/* Button */
.review-tab-button {
  padding: 10px 20px;
  background-color: #ffbf3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Popup Overlay */
.review-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Container */
.review-popup-container_two {
  background-color: #bc9c22; /* Golden color */
  padding: 20px;
  border-radius: 8px;
  width: 690px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  /* height:90% ;  */
}
.review-popup-container_three {
  background-color: #bc9c22;
  padding: 20px;
  border-radius: 8px;
  width: 690px;
  max-height: 95vh;
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.review-popup-container {
  background-color: #bc9c22; /* Golden color */
  padding: 20px;
  border-radius: 8px;
  width: 690px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Title */
.review-popup-container h2 {
  color: white;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

/* Star Rating */
.review-stars {
  display: flex;
  margin-bottom: 50px;
}

/* Review Input */
.review-textarea textarea {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
}

/* Popup Buttons */
.review-popup-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Cancel Button */
.review-cancel-btn {
  background-color: #d9d9d9;
  color: #333;
  padding: 15px;
  /* padding-left: 50px; */
  width: 30%;
  /* padding-right: 50px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Save Button */
.review-save-btn {
  background-color: #011434; /* Dark blue */
  color: white;
  /* padding: 10px 20px; */
  padding: 15px;

  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 60%;
}
.Review_txtt {
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: white;
}
.service_rating_txt {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
  color: white;
  margin-bottom: 20px;
  margin-left: 10px;
}
.textttttttt {
  font-size: 17px;
  font-weight: 700;
  /* line-height: 50px; */
  text-align: left;
  color: white;
  margin-bottom: 20px;
}

.Contact_Information_txtt {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  width: 30%;
  padding: 10px;
  border-radius: 6px;
}

.custom-dashboard-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-right: 5%;
  padding-left: 5%;
}
.image_background_div {
  background-color: #011434cc;
  padding: 10px;
  border-radius: 30px;
  height: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.card_div_style {
  border: 1px solid #011434;
  display: flex;
  width: 40%;
  padding: 20px;
  border-radius: 6px;
}
.space_div_style {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.Assessments_txtt {
  /*   /* font-family: Urbanist; */

  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #011434;
}
.Inactive_txtttt {
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.Inactive_txtttt_count {
  /* font-family: Urbanist; */
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #000000;
}
.card_div_style_two {
  border: 1px solid #bc9c22;
  display: flex;
  width: 40%;
  padding: 20px;
  border-radius: 6px;
}

.image_background_div_two {
  background-color: #bc9c22;
  padding: 10px;
  border-radius: 30px;
  height: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.compliance-summary {
  font-family: "Arial", sans-serif;
  padding: 20px;
}

.header {
  color: #c5922a;
  font-size: 24px;
  margin-bottom: 20px;
}

.compliance-sections {
  display: flex;
  justify-content: space-between;
}

.compliance-box {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  /* padding: 15px; */
  width: 100%;
  border: 1px solid #011434;
}

.section-title {
  /* font-family: Urbanist; */
  font-size: 27px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #011434;
  margin-bottom: 15px;
  text-align: left;
}

.compliance-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.compliance-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  /* border-bottom: 1px solid #e0e0e0; */
}

.compliance-item:last-child {
  border-bottom: none;
}

.item-icon {
  font-size: 24px;
  margin-right: 10px;
}

.item-details {
  /* flex: 1; */
  /* border: 1px solid red; */
  width: 80%;
}

.item-title {
  font-weight: bold;
  margin: 0;
  text-align: left;
}

.item-description,
.item-name {
  font-size: 14px;
  color: #555;
  margin: 0;
  text-align: left;
}

.item-expiry {
  font-size: 14px;
  color: #999;
}

.view-all {
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #bc9c22;
  margin-right: 10px;
}

.view-all:hover {
  text-decoration: underline;
}

.hr-compliance .section-title {
  color: #c5922a;
}

.view_all_div_ {
  border-top: 1px solid #011434;
  /* padding-bottom: 10px; */
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px;
  cursor: pointer;
}
.input-field-container {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000000;
}

.input-wrapper {
  position: relative;
}

.input-box {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #01143480;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.input-with-icon {
  padding-right: 30px;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
}
.provider-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  padding: 20px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  display: flex;
  align-items: center;
}

.profile-image img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.upload-btn {
  width: Fixed (106px) px;
  height: Fixed (40px) px;
  /* top: 272px;
  left: 807px; */
  padding: 10px 22px 10px 22px;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid #011434;
  background-color: white;
  color: #011434;
}

.close-btn {
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.provider-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  /* border: 1px solid red; */
  width: 60%;
  align-self: flex-end;
}

.cancel-btn {
  background-color: #8d99ae;
  width: 27%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 700;
  line-height: 28.8px;
  color: #011434;
}

.cancel-btn {
  background-color: #ddd;
  color: #333;
}

.submit-btn {
  background-color: #011434;
  color: #fff;
  width: 55%;
  height: 60px;
  top: 1371.32px;
  left: 1382.53px;
  gap: 0px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28.8px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0a1e58;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide the default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

/* The round part of the slider */
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* When the switch is checked */
input:checked + .slider {
  background-color: #011434;
}

/* Move the round part to the right when checked */
input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded slider */
.slider.round {
  border-radius: 34px;
}

.display_div {
  width: 100;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  padding-right: 20px;
}
.I_have_txt {
  text-align: left;
  padding-left: 10px;
}

.appointments-table-container1 {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 10px;
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); */
}
.appointments-table1 {
  width: 100%;
  /* border-collapse: collapse; */
  font-family: "Arial", sans-serif;
  font-size: 17px;
}

.image_teeth {
  height: 70px;
  width: 70px;
}

.clinic-button .clinic-icon {
  margin-right: 10px;
  background-color: #0a1e48;
  padding: 10px; /* Reduced padding */
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; /* Set a fixed height */
  width: 50px; /* Set a fixed width */
}

.clinic-button .image_teeth {
  max-width: 100%; /* Ensure the image scales within its container */
  max-height: 100%; /* Prevents overflow */
  object-fit: contain; /* Ensures the image fits within the bounds */
}

.clinic-button .clinic-label {
  font-family: Arial, sans-serif;
  font-size: 20px; /* Adjusted font size for better fit */
  font-weight: bold;
}

.clinic-button:hover {
  background-color: #eef2f9;
}

.clinic-button.active {
  background-color: #0a1e48;
  color: #fff;
}

@media (max-width: 768px) {
  .clinic-buttons-container {
    grid-template-columns: 1fr;
  }
}

.clinic-buttons-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  justify-items: center;
  padding: 20px;
  margin: 10px;
}
.clinic-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 500px;
  height: 160px; */
  border: 2px solid #0a1e48;
  border-radius: 10px;
  background-color: #fff;
  color: #0a1e48;
  cursor: pointer;
  padding-left: 40px;
  padding-right: 40px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.clinic-button .clinic-icon {
  margin-right: 10px;
  background-color: #0a1e48;
  padding: 15px;
  border-radius: 50px;
}
.clinic-button .clinic-label {
  font-family: Arial, sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.clinic-button:hover {
  background-color: #eef2f9;
}
.clinic-button.active {
  background-color: #0a1e48;
  color: #fff;
}
@media (max-width: 768px) {
  .clinic-buttons-container {
    grid-template-columns: 1fr;
  }
}

/* Container to center the profile cards */
.profileContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* Profile card styling */
.profileCard {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  border-top: 1px solid #011434;
  padding-right: 50px;
}

/* Profile image styling */
.profileImage img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

/* Profile information section */
.profileInfo {
  flex-grow: 1;
  padding-left: 20px;
  margin-left: 20px;
}

.profileName {
  font-size: 18px;
  font-weight: bold;
  color: #1a1a1a;
  text-align: left;
}

.profileEmail {
  font-size: 14px;
  color: #666;
  margin: 4px 0;
  text-align: left;
}

.profileRoles {
  font-size: 14px;
  color: #888;
  text-align: left;
}

/* Edit section styling */
.editSection {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.editIcon {
  font-size: 18px;
  color: #f5a623;
}

.editText {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.22588224709033966px;
  text-align: center;
  color: #bc9c22;
  margin-left: 5px;
}

/* Responsive styling */
@media (max-width: 600px) {
  .profileCard {
    flex-direction: column;
    align-items: flex-start;
  }

  .editSection {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
.usersetting_div_dropdown {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.New_User_div_style {
  align-items: center;
  display: flex;
  background-color: #bc9c22;
  padding: 10px;
  border-radius: 7px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.22588224709033966px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.add-new-user-wrapper {
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.user-type-selection-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-type-inactive-btn {
  background-color: #f5f5f5;
  /* border: 1px solid #ccc; */
  padding: 10px 20px;
  /* margin-left: 10px; */
  cursor: pointer;
  /* border-radius: 5px; */
  border: none;
}

.user-type-active-btn {
  background-color: #011434;
  color: white;
  padding: 10px 20px;
  /* margin-left: 10px; */
  /* border-radius: 5px; */
}

.account-info-title {
  /* font-family: Urbanist; */
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
  margin-top: 40px;
  color: #011434;
}

.roles-checkboxes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.form-actions-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  margin-bottom: 50px;
}

.form-cancel-btn,
.form-save-btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.form-cancel-btn {
  width: 30%;
  height: 50px;
  border: 1px solid #8d99ae;
  border-radius: 6px;
  background-color: #d9d9d9;
  color: #011434;
}

.form-save-btn {
  width: 30%;
  height: 50px;
  border: 1px solid #8d99ae;
  border-radius: 6px;
  background-color: #011434;
  color: #ffffff;
}

.roles-selection-container {
  margin-bottom: 30px;
}

.roles-title {
  /* font-weight: normal;
  margin-left: 5px; */
  /* text-align: left; */
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  /* border: 1px solid red; */
}

.learn-more-link {
  margin-left: 10px;
  font-size: 0.875rem;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}
.roles-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.checkbox-label {
  display: flex;
  align-items: center;
}
.ProductsforthisProvider {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
  margin-bottom: 10px;
}

.product_div_box {
  border: 1px solid #01143480;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.product_div_ {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #011434;
  width: 100%;
}

.card_product_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 50%;
  border-right: 1px solid #011434;
}

.card_product_div:last-child {
  border-right: none;
}

.product_content {
  display: flex;
  align-items: center;

  /* border: 1px solid red; */
}

.product_content img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.product_content div {
  font-size: 16px;
}

input[type="checkbox"] {
  /* Ensure the checkbox is aligned at the end */
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .product_content {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .product_div_ {
    flex-direction: column;
    align-items: flex-start;
  }

  .card_product_div {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #011434;
  }

  .card_product_div:last-child {
    border-bottom: none;
  }

  .product_content {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .product_content {
    width: 100%;
  }
}

.checkbox_input {
  width: 20px;
  height: 20px;
  accent-color: #bc9c22;
}
.Doctor_Details_txtt {
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
}

.doctor-details-container {
  /* padding: 20px; */
  width: 100%;
  margin: auto; /* Centers the content */
}

/* Doctor header section with image and details */
.doctor-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.doctor-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.doctor-info {
  display: flex;
  flex-direction: column;
  margin-left: 10;
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tabs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-bottom: 40px;
  /* justify-content: space-between; */
  padding-top: 20px;
  border-top: 1px solid #dedede;
  padding-left: 20px;
}

.tab-button {
  /* padding: 10px 20px; */
  border: 1px solid #cccccc;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;

  text-align: center;
}

.tab-button.active {
  background-color: #011434;
  color: white;
}

.tab-content {
  padding: 20px;
}

/* Doctor form layout for input fields */
.doctor-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows inputs to wrap for smaller screens */
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap; /* Buttons wrap for smaller screens */
}

.cancel-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button {
  padding: 10px 20px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* MEDIA QUERIES */

/* Responsive design for tablets and below */
@media (max-width: 768px) {
  .doctor-header {
    flex-direction: column;
    align-items: flex-start; /* Aligns image and details vertically */
  }

  .doctor-image {
    margin-bottom: 15px;
  }

  .form-row {
    flex-direction: column;
    gap: 15px; /* Adjust gap between form fields */
  }

  .button-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .tabs-container {
    flex-direction: column;
  }
}

/* Responsive design for very small screens (mobile) */
@media (max-width: 480px) {
  .doctor-details-container {
    padding: 15px;
  }

  .tab-button {
    padding: 8px 10px;
    font-size: 14px; /* Smaller font size for mobile */
  }

  .form-group input {
    padding: 8px;
    font-size: 14px;
  }

  .cancel-button,
  .save-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.Appointments_txt_Appointspatients {
  font-size: 1.5em;
  font-weight: 600;
  color: #114344;
  /* font-family: Urbanist; */
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.display_image_txt {
  display: flex;
  align-self: flex-start;
  padding-left: 30px;
  padding-top: 20px;
  align-items: center;
}
.Care_Team_Main_Div {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
}
.Care_main_div {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 25px;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid gray;
  width: 220px;
}
.padding_div {
  padding-left: 30px;
}
.display_pic {
  display: flex;
  justify-content: flex-start;
}
.imageGirl {
  resize: both;
  height: 50px;
  width: 50px;
  align-self: center;
  border-radius: 50px;
  background-color: rgb(247, 242, 242);
  margin-right: -10px;
  border: 2px solid gray;
}
.display {
  display: flex;
  justify-content: flex-start;
  border: 1px solid gray;
  border-radius: 30px;
  padding: 5px;
  margin-top: 7px;
  /* padding-left: 5px;
  padding-right: 5px; */
  background-color: lightgray;
}
.img_icon {
  resize: both;
  height: 20px;
  width: 20px;
  align-self: center;
  margin-right: 5px;
}
.text_hope {
  text-align: left;
  padding-top: 10px;
}
.text_urgent {
  text-align: left;
  color: gray;
  padding-top: 10px;
}
.txt_Ask {
  text-align: left;
  color: gray;
  padding-right: 10px;
}

.appointments-table-container12 {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
}
.appointments-table12 {
  width: 100%;
  font-family: "Arial", sans-serif;
  font-size: 17px;
}

.client-image1 {
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
.book_now_class {
  background-color: #bc9c22; /* Removed quotes */
  color: white;
  width: 100px;
  height: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  align-content: center;
}
.book_now_class:hover {
  background-color: rgba(188, 156, 34, 1); /* Removed quotes */
}

.Patient_Journey_MainDiv {
  padding-left: 30px;
  padding-right: 30px;
}
.div_Patient_txt {
  padding-top: 20px;
}
.Patient_txt {
  font-size: 2em;
  color: #011434;
  font-weight: 600;
  font-family: Poppins;
  text-align: left;
}
.patient_div_display {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
}
.patient_image_Year {
  height: 50px;
  width: 50px;
  margin-right: 15px;
}
.patient_image1_Year {
  height: 25;
  width: 25;
  margin-right: 15px;
}
.patient_input_div {
  border: 1px solid #01143480;
  width: "100%";
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background-color: #d9d9d94d;
  margin-top: 15px;
  padding: 5px;
  margin-bottom: 20px;
}
.Patient_image_test {
  height: 40px;
  width: 35px;
  margin-right: 10px;
}
.Patient_text_div {
  width: 93%;
  text-align: left;
}
.patient_Feb_txt {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 700;
  color: #011434;
}
.Patient_image_icon {
  height: 30px;
  width: 30px;
}
.Patient_Blood_txt {
  font-size: 0.8em;
  color: #01143480;
  font-weight: 400;
  /* font-family: Urbanist; */
}
.Booking_Details_txt {
  font-size: 1em;
  font-weight: 700;
  font-family: Poppins;
  color: #011434;
  text-align: left;
  padding-bottom: 20px;
}
.Patient_thead_center {
  text-align: center;
}
.patient_font_size {
  font-size: 0.7em;
  color: #01143480;
}
.Patient_thead_center_td {
  text-align: center;
  font-size: 0.8em;
}
.time-slot_two {
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: white;
  color: #000;
}

.time-slot_two.selected {
  background-color: black;
  color: white;
}

.Finance_It_should_txt {
  font-size: 1em;
  font-weight: 500;
  /* font-family: Urbanist; */
  color: "#011434";
  text-align: left;
}
.Finance_div_text {
  border: 1px solid #01143480;
  border-radius: 20px;
  width: 330px;
  margin-top: 20px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Visit_Count_txt {
  font-size: 2em;
  font-weight: 500;
  /* font-family: Urbanist; */
  color: "#011434";
}
.dashboard-cardss {
  flex-direction: column;
  gap: 10px;
}
.Finance_div_display {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 10px;
}
.Finance_Current_txt {
  font-size: 1.5em;
  font-weight: 500;
  font-family: Poppins;
  color: "#BC9C222";
  text-align: left;
}
.Finance_Patient_text_div {
  text-align: left;
}
.Finance_Pay_txt {
  background-color: #011434;
  color: #fff;
  font-size: 0.8em;
  padding: 8px;
  border-radius: 6px;
  margin-left: 20px;
}
.patient_input_div1 {
  border: 1px solid #01143480;
  width: "100%";
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border-radius: 10px;
  background-color: #d9d9d94d;
  margin-top: 15px;
  padding: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.finance_display_flex {
  display: flex;
  align-items: center;
}
.Finance_Change_txt {
  background-color: #bc9c22;
  color: #fff;
  font-size: 0.8em;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  margin-left: 20px;
}
.div_Finance_text {
  text-align: left;
  padding-bottom: 30px;
}
.div_Payment_Method {
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}
.div_text_payment_method {
  padding-left: 15px;
  text-align: left;
  width: 90%;
}
.text_Insurance_payment {
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 600;
  color: #011434;
}
.text_Bill_payment {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 400;
  /* color: #01143480; */
  color: #011434;
  padding-top: 3px;
}
.image_life-insurance {
  height: 52;
  width: 52;
}
.image_check_payment {
  height: 30px;
  width: 30px;
  align-self: center;
}
.savebuttonn1 {
  width: 250px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #011434;
  border-radius: 6px;
  color: #ffffff;
  /* margin-left: 20px; */
  width: 100%;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.checkbox {
  width: 25px;
  height: 25px;
  border: 2px solid #001b47;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
  position: relative;
}

.checkbox.checked {
  background-color: #001b47;
}

.checkmark {
  color: white;
  font-size: 15px;
}

.checkbox::before {
  content: "";
  position: absolute;
  top: 70px;
  width: 100%;
  /* height: 8px; */
  background-color: #b79400;
}

.Booking_txt {
  font-size: 1.3em;
  /* font-family: Urbanist; */
  color: #011434;
  font-weight: 600;
  text-align: left;
  padding-bottom: 20px;
  padding-top: 20px;
}
.Date_text {
  font-size: 1em;
  /* font-family: Urbanist; */
  color: #01143480;
  font-weight: 600;
  width: 80%;
  text-align: left;
  padding-bottom: 10px;
}
.the_booking_txt {
  font-size: 0.8em;
  /* font-family: Urbanist; */
  font-weight: 600;
  color: #01143480;
  text-align: left;
  padding-bottom: 10px;
  /* border: 1px solid black; */
}
.display_txt_booking {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding-top: 10px;
}

.Booking_Fee_txt {
  font-size: 0.9em;
}
.Booking_10_txt {
  font-size: 1em;
}
.active-tab1 {
  background-color: #011434;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
.inactive-tab1 {
  padding: 10px 20px;
  color: #000;
  cursor: pointer;
  border: 1px solid #011434;
  border-radius: 5px;
}
.Insurance-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .dashboard-cards1 {
    flex-direction: column;
    gap: 10px;
  }
}
.Insurance_image_camera {
  height: 50px;
  width: 50px;
  margin-bottom: 5px;
}
.Insurance_div_camera {
  width: 47%;
  height: 150px;
  border-radius: 10px;
  border: 1px solid #01143480;
  align-content: center;
  background: #d9d9d94d;
}
.Insurance_Front_txt {
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.02em;
}
.Insurance_display_flex {
  display: flex;
  gap: 6%;
  padding-top: 5px;
}
.Insurance_Card_txt {
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
}
.button {
  color: #fff;
  background-color: #011434;
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 700;
  width: 20%;
  border: 1px solid #011434;
  border-radius: 6px;
}
.button1 {
  color: #011434;
  background-color: #d9d9d9;
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 700;
  width: 20%;
  border: 1px solid #8d99ae;
  border-radius: 6px;
}

.div_display_button {
  padding-top: 50px;
  padding-bottom: 20px;
  display: flex;
  gap: 6%;
  justify-content: center;
}

.active-tab12 {
  background-color: #011434;
  padding: 10px 20px;
  color: white;
  width: 20%;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #011434;
}
.inactive-tab12 {
  padding: 10px 20px;
  color: #000;
  width: 20%;
  cursor: pointer;
  border: 1px solid #8d99ae;
  border-radius: 5px;
  background-color: #d9d9d9;
}
.Private_display_div {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  gap: 1%;
}
.Private_div_text {
  align-content: center;
  text-align: left;
  width: 90%;
}
.Private_Enter_text {
  /* font-family: Urbanist; */
  font-size: 1.3em;
  font-weight: 600;
  line-height: 40px;
}
.Private_By_text {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 400;
  line-height: 18px;
  color: #011434;
}
.space-div1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 30px; */
  width: 65%;
}
.Payment_Visa_image {
  width: 141.92px;
  height: 98.4px;
}
.MuiFormControlLabel-root {
  margin-right: 20px;
}

.selected-label {
  font-weight: bold;
}

.MuiRadio-root {
  padding: 0 8px;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #011434;
}
.Subscription_div_display {
  display: flex;
  justify-content: space-between;
  gap: 2%;
}
.Subscription_Individual_plans {
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
}
.Subscri_Basic_text {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 600;
  line-height: 30px;
}
.Scrip_div_display {
  width: 380px;
  border: 1px solid #bc9c22;
  border-radius: 10px;
}
.Display_Basic_Plan {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 20px;
  border-bottom-color: 1px solid #bc9c22;
}
.pricing-card {
  width: 300px;
  border: 1px solid #f0e68c;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Urbanist", sans-serif;
  text-align: center;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0e68c;
  padding-bottom: 10px;
}

.card-header h3 {
  font-size: 1.2rem;
  color: #1a1a1a;
  margin: 0;
}

.price-tag {
  background-color: #b8860b;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.features-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
}

.features-list li {
  font-size: 0.9rem;
  padding: 5px 0;
}
.enroll-button {
  background-color: #1a1a1a;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 70%;
}
.enroll-button:hover {
  background-color: #333;
}
.div_display_button1 {
  display: flex;
  gap: 6%;
  justify-content: center;
}
.thanku_Your_text {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 400;
  /* line-height: 27px; */
  text-align: center;
  color: #01143480;
}
.div_text_imag {
  border: 1px solid #01143480;
  border-radius: 10px;
}
.div_display_text {
  display: flex;
  justify-content: space-between;
}
.Last_text {
  /* font-family: Urbanist; */
  font-size: 0.7em;
  font-weight: 400;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: left;
  color: #8d99ae;
}
.But_text {
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #011434;
}

.notifications-container {
  font-family: Arial, sans-serif;
  color: #000;
}
.notifications-title {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  color: #011434;
}
.notifications-subtitle {
  /* font-family: Urbanist; */
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #011434;
  margin-bottom: 30px;
  margin-top: 7px;
}
.notifications-section {
  margin-bottom: 30px;
}
.section-title__ {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}
.notifications-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.notifications-row div {
  font-size: 16px;
}
.notifications-row input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 2px solid #b28c36;
  background-color: #fff;
  accent-color: #b28c36;
}
.logout-modal {
  background-color: #bc9c22; /* Light gray background */
  padding-top: 10px;
  padding-bottom: 20px;
  width: 380px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-self: center;
  justify-self: center;
}
.logout-modal p {
  font-family: "Urbanist", sans-serif;
  color: #4a4a4a; /* Gray text color */
  margin-bottom: 20px;
}
.logout-buttons {
  display: flex;
  justify-content: space-around;
}
.cancel-button,
.confirm-button {
  padding: 10px 20px;
  border-radius: 4px;
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  border: 1px solid transparent;
  cursor: pointer;
  width: 30%;
}
.cancel-button {
  background-color: #d9d9d9;
  color: #011434;
  border: 1px solid #8d99ae;
}
.confirm-button {
  background-color: #011434;
  color: #fff;
  border: none;
}
.cancel-button:hover,
.confirm-button:hover {
  opacity: 0.9;
}
.logout_txt {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  color: #011434;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
}
.logout_sure {
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.modal_div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.space_between_width {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid #000;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 3 equal columns */
  gap: 12px;
  padding: 20px;
  font-family: "Arial", sans-serif;
  color: #011434;
}
.grid-header {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  padding: 12px 0;
  /* border-bottom: 2px solid #ddd; */
}
.grid-item {
  padding: 12px 0;
  font-size: 14px;
  /* border-bottom: 1px solid #ddd; */
}
.grid-item:hover {
  background-color: #f1f1f1;
}
.hiv_txtt {
  /* font-family: Urbanist; */
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: #011434;
}
.Working_Hours_txt {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #000;
}

.border_color_width {
  border: 1px solid #bc9c22;
  padding-bottom: 20px;
  border-radius: 10px;
}
.border_color_width1 {
  border-bottom: 1px solid gray;
  padding: 20px;
  border-color: #bc9c22;
}
.Basic_Plan_text {
  color: #011434;
  /* font-family: Urbanist; */
  font-size: 25px;
  font-weight: 600;
}
.Basic_text {
  background-color: #bc9c22;
  color: #fff;
  /* font-family: Urbanist; */
  font-size: 25px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}
.bullets_div {
  background-color: #000;
  height: 12px;
  width: 12px;
  border-radius: 10px;
}
.border_color_width2 {
  align-items: center;
}
.Financial_Here_text {
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 500;
  text-align: left;
}
.Financial_border_width {
  border: 1px solid #bc9c22;
  border-radius: 20px;
  padding: 20px;
}
.Financial_Total_text {
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 500;
}
.Financial_Provider_text {
  /* font-family: Urbanist; */
  font-size: 2em;
  font-weight: 600;
  text-align: left;
  padding-bottom: 10px;
}
.Financial_Avg_text {
  /* font-family: Urbanist; */
  font-size: 1.3em;
  font-weight: 500;
  color: #011434;
}
.Financial_102_text {
  /* font-family: Urbanist; */
  font-size: 2em;
  font-weight: 700;
  color: #bc9c22;
}

.Financial_div_display_text {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.Financial_200_text {
  /* font-family: Urbanist; */
  font-size: 1.2em;
  font-weight: 500;
  color: "#000";
  text-align: center;
  background-color: #d9d9d9;
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Financial_img_doctorimg {
  height: 50px;
  width: 50px;
  border: 1px solid black;
  border-radius: 25px;
}
.Financial_display_image {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}
.Financial_Acdemic_text {
  /* font-family: Urbanist; */
  font-size: 0.7em;
  font-weight: 500;
  color: #011434;
  padding-top: 5px;
}
.space-div1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.Report_text_Clinic_Report {
  /* font-family: Urbanist; */
  font-size: 1.4em;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #011434;
}
.button_Download {
  color: #fff;
  background-color: #011434;
  /* font-family: Urbanist; */
  font-size: 0.7em;
  font-weight: 600;
  width: 20%;
  border: 1px solid #011434;
  border-radius: 6px;
  height: 50px;
}
.space-div2 {
  display: flex;
  justify-content: flex-start;
  gap: "6%";
}
.Report_image_search {
  height: 22px;
  width: 22px;
  align-self: center;
}
.Report_image_more {
  height: 24px;
  width: 24px;
  align-self: center;
}
.Report_Arrow_size {
  height: 20px;
  width: 20px;
}
.Report_August_text {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 700;
  color: #011434;
  padding-left: 10px;
  padding-right: 10px;
}
.review-cancel-btn1 {
  background-color: #d9d9d9;
  color: #333;
  width: 30%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.review-save-btn1 {
  background-color: #011434;
  color: white;
  width: 30%;
  border-radius: 5px;
  font-size: 16px;
  border: none;
}
.review-popup-buttons1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.Overview_Mother_text {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}
.Lifestyle_Determinants_main_div1 {
  width: 100%;
  /* border: 1px solid red; */
  gap: "4%";
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Lifestyle_Determinants_main_div_two1 {
  width: 100%;
  /* border: 1px solid red; */
  padding: 15px;
  display: flex;
  align-items: center;
}

.Lifestyle_Determinants_chart1 {
  width: 32%;
  /* width: 250px; */
  height: 310px;
  border-radius: 10px;
  border: 1px solid #01143480;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  /* padding: 20px; */
  background-color: #d9d9d94d;
}

.Lifestyle_Determinants_div1 {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.smoking_txt1 {
  /* font-family: Urbanist; */
  font-size: 0.9em;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
}
.OverView_space-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 30px; */
  /* width: 65%; */
}
.Blood_txt1 {
  /* font-family: Urbanist; */
  font-size: 0.9em;
  font-weight: 500;
  /* line-height: 19.2px; */
  padding-top: 10px;
  text-align: left;
}

.patient_input_div2 {
  border: 1px solid #01143480;
  width: "100%";
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #d9d9d94d;
  padding: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.patient_Feb_txt1 {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 500;
  color: #011434;
}
.appointment-notification-button {
  background-color: #e2b33a;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}
/* Day row */
.day-row {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 20px 0;
  /* border-bottom: 1px solid #e5e5e5; */
}
.day-label {
  display: flex;
  /* align-items: center; */
  font-size: 18px;
  font-weight: bold;
  color: #222;
}
.switch {
  margin-right: 10px;
}
/* Time input section */
.time-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
}
.time-row {
  display: flex;
  gap: 10px;
}
.time-picker {
  position: relative;
  flex-grow: 1;
}
.time-picker input {
  width: 100%;
  padding: 12px 40px 12px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  color: #495057;
  text-align: left;
}
.time-picker .clock-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #6c757d;
}
/* Configure and Delete buttons */
.configure-shift {
  /* font-family: Urbanist; */
  font-size: 1.3em;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #bc9c22;
}
.configure-shift:hover {
  text-decoration: underline;
}
.delete-button {
  background-color: #bc9c22;
  border: none;
  font-size: 16px;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-button:hover {
  background-color: #bc9c22;
}
.prahagraf_txt {
  /* font-family: Urbanist; */
  font-size: 24px;
  font-weight: 400;
  text-align: left;
}
.calendar-working-hours-notification-button {
  background-color: #bc9c22;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
.calendar-working-hours-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.calendar-working-hours-header h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.calendar-working-hours-header p {
  font-size: 14px;
  color: #6c757d;
  margin-top: 5px;
}
.Calendar_checkbox_div {
  margin-top: 10px;
  align-items: center;
  display: flex;
}
.email_push_sms_txt {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 25.5px;
  letter-spacing: 0.22588224709033966px;
  text-align: center;
  margin-left: 5px;
}
.day-row_two {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}
.Add_Personal_Reminder_txt {
  /* font-family: Urbanist; */
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #bc9c22;
  margin-top: 20px;
  margin-bottom: 30px;
}
.close-btn_two {
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 100px;
  right: 20px;
}
.decline-btn {
  background-color: white;
  width: 27%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #bc9c22;
  cursor: pointer;
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 700;
  line-height: 28.8px;
  color: #bc9c22;
}
.input_space_div {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 20px;
  width: 100%;
  /* border: 1px solid red; */
}
.Appointment_Info_txt {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #01143480;
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.10000000149011612px;
  background-color: #d9d9d9;
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.userTable-declineBtn {
  background-color: white;
  color: #bc9c22;
  border-radius: 6px;

  border: 1px solid #bc9c22;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 10px;
}
.userTable-confirmBtn {
  border-radius: 6px;
  background-color: #bc9c22;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}
.userTable-declineBtn {
  background-color: white;
  color: #bc9c22;
  border-radius: 6px;

  border: 1px solid #bc9c22;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 10px;
}
.Confirm-btn {
  background-color: #bc9c22;
  color: #fff;
  width: 55%;
  height: 60px;

  gap: 0px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28.8px;
  cursor: pointer;
  border: none;
}
.hiv_txtt {
  text-align: left;
}

.calendar-container {
  width: 350px;
  margin: auto;
  border: 1px solid #f0e68c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Urbanist", sans-serif;
  background-color: white;
}

/* nisha 27-09-2024 */
.Gabe_Woodward_text {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 500;
}
.Available_Days_divv {
  align-items: center;
  display: flex;
  height: 44.36px;
  border-radius: 7px;
  background-color: #ffffff;
  border: 1px solid #01143480;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
}
.unique-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 70vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
}

/* Individual day item */
/* .unique-day-item {
  border: 1px solid #01143480;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #D9D9D94D;
  transition: background-color 0.3s ease;
} */
.unique-day-item {
  border: 1px solid #01143480;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #d9d9d94d;
  transition: background-color 0.3s ease;
}

.unique-day-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unique-time-selector {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.unique-time-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Time Input Styles */
.unique-time-input-wrapper {
  position: relative;
}

.unique-time-input {
  background-color: #011d44;
  color: white;
  border-radius: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.unique-time-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Active Day Item background */
.unique-active-day {
  background-color: #f5f7fb;
}

/* Switch Styles */
.unique-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.unique-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.unique-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.unique-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .unique-slider {
  background-color: #011d44;
}

input:checked + .unique-slider:before {
  transform: translateX(20px);
}

.unique-round {
  border-radius: 34px;
  /* nisha */
}
.input-row1 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* margin-bottom: 15px; */
}
.space-div1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 30px; */
  width: 65%;
  /* border: 1px solid red; */
}
.service_rating_txt1 {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
  color: white;
  /* margin-bottom: 20px; */
  margin-left: 10px;
}
.service_rating_txt2 {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
  color: white;
  margin-bottom: 5px;
  margin-left: 10px;
}
.CareTeamButton {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #cccccc;
  width: 110px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}
.CareTeamButtonActive {
  background-color: #011434;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  /* border: 1px solid #cccccc; */
  width: 110px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: white;
  border-radius: 6px;
  cursor: pointer;
}
.Pop_Up_add_icon {
  height: 35px;
  width: 35px;
}
.Popup_Preventive_text {
  /* font-family: Urbanist; */
  font-size: 1.1em;
  font-weight: 700;
  text-align: left;
  color: #011434;
}
.Popup_Colorectal_text {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 400;
  text-align: left;
  color: #011434;
  padding-top: 5px;
}
.Popup_display_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #d9d9d9;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
}
/* nisha */

/* nisha 28-09-24 */
.div_button_Report {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.button_Report_content {
  background-color: #bc9c22;
  width: 200px;
  height: 50px;
  border-radius: 6px;
  align-content: center;
}
.button_Report_text {
  font-family: Poppins;
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
  color: #fff;
}
.button_Apply_Filter_text {
  background-color: #d9d9d9cc;
  padding: 10px;
  color: #011434;
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  margin-top: 7px;
}
.Date_To_Status_text {
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 600;
  text-align: left;
  margin-top: 5px;
}
.button_Export_PDF_text {
  background-color: #d9d9d9cc;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: #011434;
  /* font-family: Urbanist; */
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  margin-top: 7px;
}
.Button_Export_PDF {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.patient_Invoice_Report {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 700;
  color: #011434;
  text-align: left;
  padding-bottom: 2px;
}
.Patient_Summarize_txt {
  font-size: 0.8em;
  color: #01143480;
  font-weight: 400;
  /* font-family: Urbanist; */
  text-align: left;
}
.New_Payment-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 40px;
  border: 1px solid #011434;
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 600;
  text-align: center;
}
.Create_Carge_txt {
  color: #fff;
  font-size: 1em;
  text-align: center;
}
.div_button_Create {
  height: 50px;
  width: 312px;
  align-content: center;
  border-radius: 6px;
  background-color: #011434;
  /* align-self: flex-start; */
  /* align-items: flex-start; */
  padding-left: 20px;
  padding-right: 20px;
}
.Create_Carge_txt1 {
  height: 35px;
  align-content: center;
  border-radius: 6px;
  background-color: #011434;
  align-self: flex-start;
  padding-left: 20px;
  padding-right: 20px;
}
.Patient_Summarize_txt {
  font-size: 0.8em;
  color: #011434;
  font-weight: 400;
  /* font-family: Urbanist; */
  text-align: left;
}

/* NISHA 30-09024 */
.Finance_Encounter_Ative_txt {
  background-color: #011434;
  color: #fff;
  font-size: 1em;
  padding: 15px 12px;
  border-radius: 6px;
  /* margin-left: 20px; */
  border: 1px solid #011434;
  /* font-family: Urbanist; */
  font-weight: 600;
  text-align: center;
}
.Finance_Encounter_InAtive_txt {
  color: #011434;
  font-size: 1em;
  padding: 15px 15px;
  border-radius: 6px;
  margin-left: 20px;
  border: 1px solid #011434;
  /* font-family: Urbanist; */
  font-weight: 600;
  text-align: center;
}
.care_team_display {
  display: flex;
  justify-content: flex-start;
  border: 1px solid gray;
  border-radius: 30px;
  padding: 5px;
  margin-top: 7px;
}
.div_text_imag_Patient {
  border: 1px solid #01143480;
  border-radius: 6px;
  padding: 20px;
  height: 50px;
}
.div_text_imag_Patient_inactive {
  border: 1px solid #01143480;
  border-radius: 6px;
  padding: 20px;
}
.setting_Image_upper_arrow {
  height: 15px;
  width: 15px;
}
.But_text1 {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 600;
  text-align: left;
  color: #011434;
}
.space-div1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
  width: 65%;
}
.display_week_calender {
  border-bottom: 1px solid #01143480;
}
/* END OF CSS */

/* Nisha Css  01-10-24 */

/* CalenderComponent Css Start */
.custom-calendar {
  max-width: 300px;
  margin: 0 auto;
  /* border: 1px solid #ddd; */
  font-family: Arial, sans-serif;
}
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #23395b;
  /* color: white; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.calendar-header h2 {
  margin: 0;
  color: #fff;
}
button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
.weekdays-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  padding: 5px 0;
  /* background-color: #F3F4F6; */
  border-bottom: 2px solid #ddd;
  margin-bottom: 10px;
}
.weekday,
.calendar-day {
  padding: 10px;
  text-align: center;
  font-size: 1em;
}
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 2px;
}
.calendar-day {
  background-color: #fff;
  cursor: pointer;
  /* color: #fff; */
}
.calendar-day:hover {
  background-color: #f0f0f0;
}
.highlighted {
  background-color: #d4af37;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 25px;
}
.blank-day {
  /* background-color: #f0f0f0; */
}
/* Calender Css End */
.calendar-header1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
.weekday1,
.calendar-day {
  padding: 10px;
  text-align: center;
  font-size: 0.7em;
  color: rgba(1, 20, 52, 0.7);
}
.calendar-day1 {
  background-color: #fff;
  cursor: pointer;
  color: rgba(1, 20, 52, 0.7);
  padding: 10px;
}
.border_div {
  border: 1px solid black;
  padding: 0px 5px;
  border-radius: 5px;
}
.AddNew_main_div {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
}
.Clinic_details_text {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 700;
  text-align: left;
  padding-top: 20px;
}
.Birmingham_text {
  /* font-family: Urbanist; */
  font-size: 1.5em;
  font-weight: 600;
  text-align: left;
}
.doctor_clinic_main_div {
  flex-direction: column;
}
.starimage {
  height: 38px;
  width: 40px;
}
.messageimage {
  height: 37px;
  width: 37px;
}
.Review_text {
  font-family: Poppins;
  font-size: 1.3em;
  font-weight: 400;
  text-align: left;
}
.div_message_image {
  height: 70px;
  width: 70px;
  border-radius: 35px;
  background-color: #011434;
  align-content: center;
}
.Donctor_display_image {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}
.Donctor_display_image_text {
  display: flex;
  padding-top: 20px;
  align-items: center;
  gap: 50px;
}
.Join_Clinic_text {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding: 15px 35px;
  background-color: #bc9c22;
  border-radius: 13px;
  align-content: center;
}
.Doctor_Email_Address_text {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 600;
  color: #011434;
  padding-top: 10px;
  padding-bottom: 5px;
}
.doctor_email_text {
  font-family: Poppins;
  font-size: 0.9em;
  font-weight: 400;
  color: #011434;
  padding-bottom: 20px;
}
.Doctor_div_text {
  border-right: 1px solid #01143480;
}
.doctor_email_text1 {
  font-family: Poppins;
  font-size: 0.9em;
  font-weight: 400;
  color: #011434;
  padding-bottom: 3px;
}
.clinic_doctor_div_text {
  border-top: 1px solid #01143480;
  margin-top: 20px;
}
.div_h3_h4_display {
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Calen_Appointment_update {
  /* font-family: Urbanist; */
  font-size: 30px;
  font-weight: 400;
  /* text-align: left; */
}
.Calen_Email_text {
  /* font-family: Urbanist; */
  font-size: 20px;
  font-weight: 400;
}
.form-actions1 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  /* border: 1px solid red; */
  width: 60%;
  align-self: center;
}
/* END OF CSS */

/* Nisha  3 oct 2024  */
.space-div12 {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-top: 30px;
  width: 65%;
}
.image_arrow_setting {
  height: 15px;
  width: 15px;
}
.Privacy_div_display_text {
  /* border: 1px solid #01143480; */
  border: 1px solid rgba(1, 20, 52, 0.5);
  border-radius: 10px;
  align-content: center;
  padding: 20px 10px;
}
.Privacy_div_display_active_text {
  /* border: 1px solid #01143480; */
  border: 1px solid rgba(1, 20, 52, 0.5);
  border-radius: 10px;
  height: 45px;
  align-content: center;
  padding: 0px 10px;
}
.display_text_image {
  display: flex;
  justify-content: space-between;
}
.Privacty_Setting_text {
  /* font-family: Urbanist; */
  font-size: 1em;
  font-weight: 600;
  /* text-align: center; */
  color: #011434;
}

.schedule-button_two {
  border: 1px solid #011434;
  color: #011434;
  /* font-family: Urbanist; */
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  padding: 10px;
  border-radius: 30px;
}

.error-message {
  text-align: left;
  color: red;
}
.main_div {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  align-items: center;
}

.error_message {
  color: red;
}
/* END OF CSS */
.display_div_dis {
  display: flex;
  /* margin-left: 40px; */
  /* align-self: flex-start; */
  /* text-align: left; */
  /* border: 1px solid red; */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it sits on top of everything */
}

.modal-content {
  /* background-color: white; */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #f3f3f3; /* Light gray */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Add_button__ {
  width: 100px;
  padding: 10px;
  color: white;
  background-color: #bc9c22;
  border-radius: 5px;
  align-self: flex-end;
}

.Provider_Registration {
  font-size: 26px;
  font-weight: 900;
  text-align: left;
  width: 100%;
  margin-top: 15px;
}
.register_provider_ {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}
.Custom_input {
  height: 50px;
}
.css-13uujjn {
  max-width: 500px;
  width: 100% !important;
  /* height: auto;
  object-fit: cover; */
  height: fit-content !important;
}
.Custom_servicer_modalMain {
  height: 90vh !important;
}
.admin_cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.Clinical_Settings {
  width: 20%;
  margin-right: 20px !important;
  margin-top: 15px;
}
.General_Settings {
  margin-right: 20px;
  margin-top: 15px;
  width: 20%;
}

@media (max-width: 520px) {
  .Custom_input {
    height: 40px;
  }
  .css-13uujjn {
    max-width: 100%;
    width: 100%;
  }
}

:root {
  --primary_color: #3cb8cb;
  --white: #ffffff;
  --dark_heading: #000000;
  --dark_color: #343f52;
  --light_text: #161c2d;
  --blue: #0082dc;
  --light: #333333;
  --full_width: 100%;
  --label_color: #666666;
  --cardSecondBg: #3507fe;
  --cardThirdBg: #0793fe;
}
h1 {
  color: var(--dark_heading);
  font-size: 30px;
}
h2 {
  color: var(--dark_color);
  font-size: 30px;
}
h3 {
  color: var(--dark_color);
  font-size: 24px;
}
section {
  padding-top: 30px;
}
.text-small {
  font-size: 16px;
  color: var(--light_text);
}
.btn_dark {
  background-color: #bc9c22;
  color: var(--white);
  height: 60px;
  width: 100%;
  font-size: 22px;
  border: 0px;
  border-radius: 40px;
}
.input {
  width: var(--full_width);
  border-radius: 12px;
  border: 1px solid var(--label_color);
  padding: 0px 10px;
  height: 54px;
}
.label {
  font-size: 16px;
  color: var(--label_color);
}
.custom_login--width .modal-content {
  height: auto !important;
  width: auto !important;
}
.login {
  background-color: rgb(4, 28, 50);
  height: 100vh;
}
.login_content .modal-content {
  padding: 30px;
  border-radius: 20px;
}
.heading_label {
  font-size: 28px;
}
.login_content form {
  text-align: start;
}
.login_content .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.login .modal-body strong {
  font-size: 24px;
  color: var(--dark_heading);
}
@media (max-width: 990px) {
  .login_content .modal-content {
    padding: 0px;
  }
  .btn_dark {
    font-size: 16px;
    border-radius: 12px;
  }
  .btn_light {
    height: 44px;
    font-size: 16px;
    border-radius: 12px;
  }
  .label {
    font-size: 12px;
  }
  h1 {
    font-size: 14px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 18px;
  }
}

.Account_txttttt {
  font-size: 20px;
  font-weight: 800;
}
.checkbox-form {
  max-width: 700px;
}

.form-group_two {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-label {
  font-size: 16px;
}

.form-checkbox {
  margin-left: 10px;
}

.form-checkbox:disabled {
  cursor: not-allowed;
  background-color: #f0f0f0;
}

.unique-form-container {
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  border-radius: 8px;
}

.unique-form-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #002855;
}

.unique-pay-frequency {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.unique-pay-frequency-btn {
  flex: 1;
  padding: 10px 0;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: black;
}

.unique-pay-frequency-btn.unique-active {
  background-color: #ffbf00;
  color: white;
  border: none;
}

.unique-form-group {
  margin-bottom: 15px;
}

.unique-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.unique-form-group input,
.unique-form-group select,
.unique-form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.unique-form-group textarea {
  resize: none;
  height: 60px;
}

.unique-create-plan-btn {
  width: 100%;
  padding: 10px;
  background-color: #002855;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.unique-create-plan-btn:hover {
  background-color: #0056b3;
}
/* General container styles */
.document-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* padding: 20px; */
}

/* Section Styles */
.section {
  font-family: Arial, sans-serif;
}

/* Section Titles */
.section-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Checkbox container styles */
.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Checkbox items */
.checkbox-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

/* Checkbox input styles */
.checkbox-input {
  margin-right: 8px;
  height: 10px;
  width: 10px;
  transform: scale(1.1); /* Slightly larger for visibility */
}
.unique-container {
  /* max-width: 800px; */
  margin: 20px auto;
  font-family: Arial, sans-serif;
  color: #333;
}

h3,
h4 {
  margin-bottom: 10px;
}

.unique-payors-section {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.unique-payors-list {
  /* display: flex; */
  /* flex-wrap: wrap; */
  gap: 10px;
}

.unique-payor-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid grey;
  margin-top: 10px;
}

.unique-referrals-section {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  /* align-i; */
}

.unique-checkbox-container {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.unique-pay-rates-section {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.unique-pay-rates-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.unique-pay-rates-table th,
.unique-pay-rates-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.unique-pay-rates-table th {
  background-color: #1d3557;
}
/* Container Styling */
.practice-container {
  /* max-width: 900px; */
  /* margin: 20px auto; */
  font-family: Arial, sans-serif;
  color: #333;
}
.pay-rates-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pay-rates-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px;
  width: 100%; /* Adjust width as per requirement */
}

.pay-rates-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 10px;
}

.pay-rates-item h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.pay-rates-item p {
  font-size: 16px;
  font-weight: bold;
}

/* Practice Locations Section */
.practice-locations-section {
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
}

.practice-locations-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.practice-locations-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.practice-location-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #dcdcdc;
}

.practice-location-item:last-child {
  border-bottom: none;
}

.practice-location-actions button {
  margin-left: 10px;
}

.practice-edit-btn,
.practice-archive-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.practice-add-btn {
  display: inline-block;
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

/* Telehealth Section */
.telehealth-section {
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}

.telehealth-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.telehealth-description {
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.telehealth-input-container {
  margin-bottom: 10px;
}

.telehealth-input-label {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.telehealth-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  font-size: 14px;
}

.telehealth-note {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
}

.telehealth-requirements {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
}

.telehealth-requirements li {
  font-size: 14px;
  margin-bottom: 5px;
}

.telehealth-learn-more {
  display: inline-block;
  color: #007bff;
  font-size: 14px;
  text-decoration: none;
  margin-top: 10px;
}
/* Pay Rate Container */
.pay-rate-container {
  /* max-width: 800px; */
  margin: 20px auto;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

/* Title Section */
.pay-rate-title {
  font-size: 24px;
  text-align: center;
  color: #1f2b49;
  margin-bottom: 8px;
}

.pay-rate-subtitle {
  font-size: 14px;
  text-align: center;
  color: #555;
  margin-bottom: 20px;
}

/* Contracted CPT Code Rates Section */
.cpt-code-section {
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 8px;
  background-color: #e9f0f7;
  margin-bottom: 20px;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1f2b49;
}

.cpt-code-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
}

.cpt-code-table th,
.cpt-code-table td {
  border: 1px solid #dcdcdc;
  padding: 10px;
  text-align: left;
}

.cpt-code-table th {
  background-color: #f1f5fa;
  font-weight: bold;
  color: #1f2b49;
}

.cpt-code-table td {
  background-color: #fff;
}

.rate-input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  background-color: #f9f9f9;
}

.view-contract-btn {
  background-color: #1f2b49;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.view-contract-btn:hover {
  background-color: #14203a;
}

/* Contracted Hourly Rates Section */
.hourly-rate-section {
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 8px;
  background-color: #e9f0f7;
}

.hourly-rate-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hourly-rate-row span {
  font-weight: bold;
  color: #1f2b49;
}

.hourly-rate-row .rate-input {
  width: 80px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  background-color: #f9f9f9;
}

.responsive-form {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  font-family: Arial, sans-serif;
  /* background-color: #f9f9f9; */
  /* border: 1px solid #ddd; */
  border-radius: 8px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-label {
  font-weight: bold;
  color: #333;
  flex: 1;
}

.form-value {
  flex: 2;
  text-align: right;
  color: #555;
  word-break: break-word;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-value {
    text-align: left;
    margin-top: 5px;
  }
}
.custom-req-table-container {
  padding: 20px;
}

.custom-req-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.custom-req-table th,
.custom-req-table td {
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
}

.custom-req-table thead {
  background-color: #002855;
  color: white;
}

.custom-req-table th {
  font-weight: bold;
}

.custom-req-table td button {
  margin: 2px;
  padding: 8px 12px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.custom-req-upload-button {
  background-color: #007bff;
  color: white;
}

.custom-req-download-button {
  background-color: #28a745;
  color: white;
}

.custom-req-mark-exempt-button {
  background-color: #ffc107;
  color: black;
}

.custom-req-send-request-button {
  background-color: #6c757d;
  color: white;
}

.user-table-popup-menu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  z-index: 1000;
  min-width: 150px;
}

.user-table-popup-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-table-popup-menu li {
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-table-popup-menu li:hover {
  background-color: #f0f0f0;
}

.view-button {
  position: relative; /* To position popup relative to the button */
}

tr {
  position: relative; /* Ensure each row has a relative position */
}

/* Container */
.rates-container {
  background-color: #0c2136;
  padding: 20px;
  /* padding-bottom: 20px; */
  color: white;
  font-family: Arial, sans-serif;
}

/* State buttons */
.states-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.state-button {
  background-color: #d1a227;
  border: none;
  color: #0c2136;
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.state-button.active {
  background-color: #146ca0;
  color: white;
}

/* State Title */
.state-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  /* color: #f0f0f0; */
}

/* Table styling */
.rates-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  color: black;
  border-radius: 8px;
  overflow: hidden;
}

.rates-table th,
.rates-table td {
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.rates-table th {
  background-color: #1c3b57;
  color: white;
  font-weight: bold;
}

.rates-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.rates-table tr:nth-child(odd) {
  background-color: #e9ecef;
}

/* Responsive header styling */
.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .rates-table th,
  .rates-table td {
    font-size: 12px;
    padding: 8px;
  }

  .header-content {
    text-align: center;
  }

  .view-contract {
    font-size: 10px;
    padding: 5px 10px;
  }
}

/* View Contract Button */
.view-contract {
  background-color: #f3722c;
  border: none;
  color: white;
  padding: 5px 10px;
  margin-top: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.view-contract:hover {
  background-color: #f3722c;
}
/* Container styling */
/* .tabs-container_two {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
} */

/* Top row: Insurance Tabs */
/* .insurance-tabs-row {
  display: flex;
  gap: 10px;
} */
.tabs-container_two {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: thin; /* For Firefox */
}

.insurance-tabs-row {
  display: flex;
  gap: 10px; /* Adjust the spacing between tabs */
  width: max-content; /* Prevent wrapping */
}

.insurance-tab {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #e5e5e5;
  color: #333;
  cursor: pointer;
  white-space: nowrap; /* Prevent text wrapping */
}

.insurance-tab.insurance-active {
  background-color: #1a3e66;
  color: #fff;
  font-weight: bold;
}

.state-tabs-row {
  display: flex;
  gap: 10px;
}

.state-tab {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #e5e5e5; /* Default gray background */
  color: #333;
  cursor: pointer;
  font-family: Arial, sans-serif;
}

.state-tab.state-active {
  background-color: #1a3e66; /* Dark blue for active state */
  color: #fff;
  font-weight: bold;
}

.state-tab:hover {
  background-color: #d4d4d4;
}

/* General Layout */
.notifications {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #ffffff;
}

.notifications__subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

/* Input Group Styles */
.notifications__input-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.notifications__row {
  display: flex;
  gap: 30px;
}

.notifications__label {
  font-size: 0.9rem;
  margin-bottom: 5px;
  font-weight: 500;
}

.notifications__input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  font-size: 0.9rem;
  margin-bottom: 10px;
  width: 100%;
}

.notifications__consent-text {
  font-size: 0.8rem;
  color: #666;
  margin-top: 10px;
  text-align: left;
}

/* Toggle Buttons */
.notifications__toggle {
  display: flex;
  gap: 5px;
}

.notifications__toggle-btn {
  padding: 5px 15px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: lightgrey;
  color: black;
  cursor: pointer;
}

.notifications__toggle-btn--off {
  /* background-color: #f44336; */
}

.notifications__toggle-btn:hover {
  opacity: 0.8;
}

/* Notification Options */

.notifications__options_main {
  display: flex;
  flex-wrap: wrap;
  /* gap: 30px; */
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.notifications__option {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  width: 20%;
  text-align: left;
}
.insurance-card-container {
  /* Adjust width as needed */
  width: 100%;
  margin: 20px auto; /* Centering */
  font-family: Arial, sans-serif;
  /* align-items: center;
  justify-content: center; */
}

.insurance-card-title {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  /* color: #6b5eac; Purple color */
  margin-bottom: 20px;
}

.card-upload-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* width: 300px; */
  /* border: 1px solid red; */
  align-items: center;
  justify-content: center;
}

.upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  height: 150px; /* Adjust height as needed */
  background-color: #f9f9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.upload-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  width: 100%;
  height: 100%;
}

.upload-text {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.uploaded-image {
  width: 300px;
  height: 155px;
  object-fit: cover;
  border-radius: 10px;
}

.notifications__title {
  color: #1d3557;
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
}

.payment-description {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.payment-section {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
}

.payment-method {
  display: flex;
  align-items: center;
  background-color: #e0e4ea;
  padding: 15px;
  border-radius: 8px;
  border-left: 5px solid #1d3557;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1;
}
.payment-method input[type="radio"] {
  margin-left: -45%;
}

.payment-method label {
  margin-left: -45%;
  margin-top: 7px;
}

.payment-method input {
  margin-right: 15px;
}

.payment-method:hover {
  background-color: #d1a227;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}

.tablinks {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e0e4ea;
  color: #041c32;
  transition: background-color 0.3s ease;
}

.tablinks.active,
.tablinks:hover {
  background-color: #d1a227;
  color: white;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #041c32;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-btn {
  display: block;
  width: 100%;
  background-color: #1d3557;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.submit-btn:hover {
  background-color: #146ca0;
}

.enroll-item {
  background-color: #e0e4ea;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  border-left: 5px solid #d1a227;
  transition: background-color 0.3s ease;
}
.container_payment {
  margin: 0 auto;
  padding: 30px;
}
.plan-name {
  font-size: 18px;
  color: #041c32;
  margin-bottom: 10px;
  font-weight: bold;
}

.plan-description {
  font-size: 14px;
  color: #333;
  margin-bottom: 15px;
}

.plan-price {
  font-size: 16px;
  font-weight: bold;
  color: #1f8ef1;
  margin-bottom: 15px;
}

.button_payment {
  background-color: #1d3557;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
}

.patientHistory-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.patientHistory-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.patientHistory-form-group {
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.patientHistory-form-group label {
  /* display: block; */
  /* margin-bottom: 5px; */
  font-weight: bold;
}

.patientHistory-btn {
  margin-right: 10px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.patientHistory-btn-secondary {
  padding: 8px 15px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.patientHistory-btn:hover {
  background-color: #0056b3;
}

.patientHistory-btn-secondary:hover {
  background-color: #5a6268;
}
.team_table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

h1 {
  text-align: center;
  color: #041c32;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.table th,
.table td {
  padding: 10px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.table th {
  background-color: #041c32;
  color: white;
}
.modal_twooo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 600px;
  max-height: 90vh; /* Limit the height to make it scrollable */
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto;
}
.modal_twooo h2 {
  font-size: 1.5rem;
  color: #041c32;
  margin-bottom: 20px;
}
.tab-buttons_twoooo {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.tab-buttons_twoooo button {
  flex: 1;
  background-color: #e0e4ea;
  color: #041c32;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
.tab-buttons_twoooo button.active {
  background-color: #041c32;
  color: white;
}
.form-group {
  margin-bottom: 15px;
  position: relative;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #041c32;
  font-weight: bold;
}
.form-group input[type="search"],
.form-group select,
.form-group input[type="date"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}
.search-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.search-dropdown li {
  padding: 10px;
  cursor: pointer;
}
.search-dropdown li:hover {
  background-color: #f0f0f0;
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.form-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
.form-buttons .cancel {
  background-color: #e0e4ea;
  color: #041c32;
}
.form-buttons .save {
  background-color: #041c32;
  color: white;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 500;
}
.tab-content_twooo {
  display: none;
}
.tab-content_twooo.active {
  display: block;
}

.notifications__toggle-btn {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.notifications__toggle-btn--on {
  background-color: #4caf50; /* Green for ON state */
  color: white;
}

.notifications__toggle-btn--off {
  background-color: #f44336; /* Red for OFF state */
  color: white;
}

.notifications__toggle-btn--off:hover,
.notifications__toggle-btn--on:hover {
  opacity: 0.8;
}

.notifications__toggle-btn.active {
  background-color: #3e8e41; /* Darker green when active */
}

.notifications__toggle-btn--off.active {
  background-color: #d32f2f; /* Darker red when active */
}
.tabs_historyyyy {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.tabs_historyyyy button {
  background-color: #e0e4ea;
  color: #041c32;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.tabs_historyyyy button:hover,
.tabs_historyyyy button.active {
  background-color: #041c32;
  color: #fff;
}
.modal_history {
  display: flex; /* Default to visible */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.modal_hidden {
  display: none; /* Add this class for hiding the modal */
}

.modal-content_history {
  background: #ffffff;
  width: 90%;
  max-width: 1000px;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 80%;
  overflow-y: auto;
}

.modal-header_history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.5rem;
  color: #041c32;
  font-weight: 900;
}

.close-btn_history {
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  color: #041c32;
}

.close-btn_history:hover {
  color: #d9534f;
}

.modal-body_history {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}

/* 
 */

body_history {
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 20px;
}
.container_history {
  /* max-width: 1000px; */
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* Header Section */
.header_history {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.header_history img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.details_history {
  flex-grow: 1;
  margin-left: 20px;
}

.details_history h2 {
  color: #041c32;
  margin: 0;
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.details_history h2 a {
  font-size: 12px;
  color: #007bff;
  text-decoration: none;
}

.details_history h2 a:hover {
  text-decoration: underline;
}

.summary_history {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 20px;
  margin-top: 10px;
}

.summary_history p {
  margin: 0;
  font-size: 14px;
}

.summary_history span {
  font-weight: bold;
  color: #041c32;
}

/* Tabs */
.tabs_history {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.tabs_history button {
  background-color: #e0e4ea;
  color: #041c32;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.tabs_history button:hover,
.tabs_history button.active {
  background-color: #041c32;
  color: #fff;
}

/* Tab Content */
.tab-content_history {
  display: none;
  margin-top: 20px;
}

.tab-content_history.active {
  display: block;
}

/* History Tab Content */
.history-sections_history {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.history-section_history {
  background: #f8f9fa;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.history-section_history h3 {
  margin: 0 0 10px;
  font-size: 1.2rem;
  color: #041c32;
  display: flex;
  align-items: center;
  gap: 10px;
}

.history-section_history i {
  font-size: 1.5rem;
  color: #00509e;
}
.history-section_history_two {
  background: #f8f9fa;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-section_history_two h3 {
  margin: 0 0 10px;
  font-size: 1.2rem;
  color: #041c32;
  display: flex;
  align-items: center;
  gap: 10px;
}

.history-section_history_two i {
  font-size: 1.5rem;
  color: #00509e;
}
/* Modal Styles */

.modal-content_history {
  background: #ffffff;
  width: 90%;
  max-width: 1000px;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 80%;
  overflow-y: auto;
}

.modal-header_history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header_history h2 {
  font-size: 1.5rem;
  color: #041c32;
}

.close-btn_history {
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  color: #041c32;
}

.close-btn_history:hover {
  color: #d9534f;
}

.modal-body_history {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}

.category_history {
  background: #f8f9fa;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category_history h4 {
  margin: 0 0 10px;
  font-size: 1.1rem;
  color: #041c32;
  display: flex;
  align-items: center;
  gap: 10px;
}

.category_history i {
  font-size: 1.5rem;
  color: #00509e;
}

.label_history {
  display: block;
  margin: 5px 0;
  font-size: 0.95rem;
  color: #333;
}

.input_history[type="checkbox"] {
  margin-right: 10px;
}

.modal-footer_history {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.modal-footer_history button {
  background-color: #e0e4ea;
  color: #041c32;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.modal-foote_historyr button:hover {
  background-color: #041c32;
  color: #fff;
}
/* Responsive Adjustments */
@media (max-width: 600px) {
  .summary_history {
    grid-template-columns: 1fr;
  }
  .modal-body_history {
    grid-template-columns: 1fr;
  }
}
.open-modal-btn_history {
  background-color: lightgray;
  border: 1px solid black;
  padding: 5px;
  font-size: 14px;
  color: #000;
  border-radius: 5px;
}

.payment-container {
  width: 700px;
  background: #f7f7f7;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.terms_twoo {
  font-size: 0.9em;
  margin: 5px 0 20px;
  color: #333;
  text-align: center;
}

.terms_twoo a {
  color: #d1a227;
  text-decoration: none;
}
.radio-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.radio-group_label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1em;
  color: #041c32;
  cursor: pointer;
  white-space: nowrap;
}

.radio-group input {
  accent-color: #1d3557;
}

.form-group_twoooo {
  margin-bottom: 20px;
  /* width:30%; */
}

.form-inline_twoooo {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}

.form-group_twoooo label {
  font-weight: 500;
  color: #041c32;
  display: block;
  margin-bottom: 5px;
}

.form-group_twoooo input,
.form-group_twoooo select {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.card-icons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.card-icons img {
  width: 50px;
  height: auto;
}

.btn-submit {
  background-color: #1d3557;
  color: #fff;
  padding: 15px;
  font-size: 1em;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #146ca0;
}
.card-list {
  margin-top: 30px;
}
.card-item {
  background: #e0e4ea;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-item span {
  color: #041c32;
  font-size: 1em;
}

.card-item.expired {
  background-color: #ffd5d5;
  border-left: 5px solid #d9534f;
}

.card-item button {
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
}

.card-item button:hover {
  background-color: #c9302c;
}
.notifications__options-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Add space between items */
}

.notifications__option label {
  margin-bottom: 8px; /* Add spacing between label and buttons */
}

.notifications__toggle-btn {
  padding: 8px 16px;
  margin-right: 8px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.notifications__toggle-btn--active {
  background-color: #4caf50; /* Green for active (ON) */
  color: white;
}

.notifications__toggle-btn--off {
  background-color: #f44336; /* Red for OFF */
  color: white;
}

.notifications__toggle-btn:hover {
  background-color: #ddd;
}

.error-text {
  text-align: left;
  color: red;
}

.form-container_twoo {
  background: #ffffff;
  width: 400px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-container_twoo_h1 {
  font-size: 1.5em;
  color: #1d3557;
  text-align: left;
  margin-bottom: 20px;
}
.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn.cancel {
  background-color: #f7f7f7;
  color: #333;
  border: 1px solid #ccc;
}

.btn.cancel:hover {
  background-color: #e0e0e0;
}

.btn.save {
  background-color: #c9302c;
  color: #ffffff;
}

.btn.save:hover {
  background-color: #a52820;
}
.container_subscription {
  width: 100%;
  /* max-width: 1200px; */
  background: #f7f7f7;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

h1 {
  font-size: 2em;
  color: #1d3557;
  text-align: center;
  margin-bottom: 30px;
}

.plan-card_subscription {
  background: #e0e4ea;
  border: 1px solid #ddd;
  border-left: 5px solid #d1a227;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plan-title_subscription {
  font-size: 1.5em;
  font-weight: 700;
  color: #041c32;
}

.plan-specialty_subscription {
  font-size: 1.2em;
  color: #555;
}

.plan-details_subscription {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 1em;
}

.plan-details_subscription span {
  display: block;
  color: #333;
}

.button_subscription {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.enroll-button_subscription {
  background-color: #1d3557;
}

.enroll-button_subscription:hover {
  background-color: #146ca0;
}

.details-button_subscription {
  background-color: #d1a227;
}

.details-button_subscription:hover {
  background-color: #b8860b;
}

.modal_subscription {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-content_subscription {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 500px;
  text-align: center;
}

.modal-content_subscription table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.modal-content_subscription th,
.modal-content_subscription td {
  padding: 10px;
  border: 1px solid #ddd;
}

.modal-content_subscription th {
  background: #1d3557;
  color: white;
}

.close-button_subscription {
  background: #d9534f;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button_subscription:hover {
  background: #c9302c;
}
:root {
  --primary-color: #041c32;
  --secondary-color: #d1a227;
  --text-color: #2c3e50;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --background-color: #f7f7f7;
}

h1 {
  color:  #333;
  font-size: 2rem;
  margin-bottom: 20px;
}
.membership-card-container {
  background: white;
  width: 400px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--secondary-color);
  margin-bottom: 30px;
}

.membership-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.membership-program-name {
  font-size: 22px;
  font-weight: bold;
  color: var(--text-color);
}

.membership-status-badge {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.membership-status-badge.active {
  background-color: var(--success-color);
}

.membership-status-badge.paused {
  background-color: var(--warning-color);
}

.membership-status-badge.expired {
  background-color: var(--danger-color);
}

.membership-card-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}

.membership-info-group {
  margin-bottom: 10px;
}

.membership-label {
  font-size: 12px;
  color: #7f8c8d;
  margin-bottom: 3px;
}

.membership-value {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 600;
}

.membership-progress-bar {
  position: relative;
  background: #e0e0e0;
  border-radius: 10px;
  height: 10px;
  margin-top: 5px;
  width: 100%;
}

.membership-progress {
  position: absolute;
  background: var(--success-color);
  border-radius: 10px;
  height: 100%;
  transition: width 0.3s ease;
}

.membership-progress-text {
  font-size: 12px;
  color: var(--text-color);
  margin-top: 5px;
}

.membership-action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.membership-action-buttons button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.membership-action-buttons button:hover {
  background-color: #b8931f;
}

.membership-card-footer {
  background: var(--secondary-color);
  padding: 10px 20px;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 10px;
}

.membership-card-footer a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.membership-card-number {
  letter-spacing: 2px;
}

@media (max-width: 768px) {
  .membership-card-container {
    width: 90%;
  }

  .membership-card-content {
    grid-template-columns: 1fr;
  }
}
/*  */
.tabs_MemberPlan {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  /* border: 1px solid red; */
}
.add-event {
  align-self: flex-start;
  color: black;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  display: flex;
}
.tab-button_MemberPlan {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #e0e4ea;
  color: #041c32;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.tab-button_MemberPlan.active {
  background-color: #d1a227;
  color: #fff;
}

.plans-container_MemberPlan {
  display: none;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.plans-container_MemberPlan.active {
  display: flex;
}

.plan-card_MemberPlan {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card_MemberPlan.clicked {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plan-title_MemberPlan {
  font-size: 1.5rem;
  font-weight: 500;
  color: #041c32;
  margin-bottom: 10px;
}

.plan-frequency_MemberPlan {
  font-size: 1rem;
  color: #888;
  margin-bottom: 15px;
}

.plan-price_MemberPlan {
  font-size: 2rem;
  color: #d1a227;
  font-weight: bold;
  margin-bottom: 20px;
}

.features_MemberPlan {
  text-align: left;
  margin-bottom: 20px;
}

.features_MemberPlan li {
  font-size: 0.9rem;
  margin-bottom: 8px;
  color: #041c32;
  display: flex;
  align-items: center;
}

.features_MemberPlan li i {
  color: #d1a227;
  margin-right: 10px;
}

.cta-buttons_MemberPlan {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.cta-button_MemberPlan {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  background-color: #d1a227;
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button_MemberPlan:hover {
  background-color: #b8860b;
}

.details-button_MemberPlan {
  background-color: #041c32;
}

.details-button_MemberPlan:hover {
  background-color: #06344a;
}
.new_modal_upgrade_plan {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
}
.modal_MemberPlan {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #ffffff; */
  width: 80%;
  /* Limit the height to make it scrollable */
  border-radius: 10px;
  padding: 25px;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; */
}

.modal-content_MemberPlan {
  background: #fff;
  color: #041c32;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content_MemberPlan table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.modal-content_MemberPlan table,
.modal-content_MemberPlan th,
.modal-content_MemberPlan td {
  border: 1px solid #ddd;
}

.modal-content_MemberPlan th,
.modal-content_MemberPlan td {
  padding: 10px;
  text-align: left;
}

.close-button_MemberPlan {
  margin-top: 20px;
  padding: 10px 20px;
  background: #d1a227;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.close-button_MemberPlan:hover {
  background-color: #b8860b;
}

.payment-options_MemberPlan {
  margin-top: 20px;
  text-align: left;
}

.payment-options_MemberPlan label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #041c32;
}
/* 
.element_MemberPlan {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
} */
.container_team {
  padding: 10px;
}
.error {
  color: red;
  text-align: left;
  /* font-size: 12px; */
}

.add-button_insuranceDetails {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-button_insuranceDetails:hover {
  background-color: #002244;
}

.modal_insuranceDetails {
  background-color: #ffffff;
  border-radius: 10px;
  width: 900px;
  max-width: 90%;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  height: 80vh;
  overflow-y: auto;
}
@media (max-width: 600px) {
  .modal_insuranceDetails {
    width: 100%;
    height: 80vh;
  }
}

.modal_insuranceDetails h2 {
  margin-top: 0;
  font-weight: 700;
  text-align: center;
}

.form-row_insuranceDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.form-column_insuranceDetails {
  flex: 1;
  min-width: 200px;
}

.form-column_insuranceDetails label {
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

.form-column_insuranceDetails input,
.form-column_insuranceDetails select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.modal_insuranceDetails button {
  width: 100%;
  background-color: #003366;
  color: #ffffff;
  border: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.modal_insuranceDetails button:hover {
  background-color: #002244;
}

.policy-holder_insuranceDetails {
  display: none;
  margin-top: 20px;
}

.insurance-list_insuranceDetails {
  background-color: #ffffff;
  width: 100%;
  max-width: 1200px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.table_insuranceDetails {
  width: 100%;
  border-collapse: collapse;
}

.th_insuranceDetails,
td_insuranceDetails {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

th {
  background-color: #003366;
  color: white;
}

td button {
  padding: 5px 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

td button:hover {
  background-color: #c0392b;
}

.check-eligibility_insuranceDetails {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.check-eligibility_insuranceDetails:hover {
  background-color: #0056b3;
}

.add-button_AddAllergy {
  background-color: #d1a227;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.add-button_AddAllergy:hover {
  background-color: #b68c1f;
}

.modal_AddAllergy {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 600px;
  max-height: 90vh; /* Limit the height to make it scrollable */
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto;
}

.modal_AddAllergy.active {
  display: block;
}

.modal_AddAllergy h2 {
  margin-top: 0;
  font-weight: 700;
  text-align: center;
}

.form-row_AddAllergy {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.form-group_AddAllergy {
  flex: 1 1 calc(50% - 10px);
  margin-bottom: 10px;
}

.form-group_AddAllergy label {
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}

.form-group_AddAllergy input,
.form-group_AddAllergy select,
.form-group_AddAllergy textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.form-group_AddAllergy textarea {
  resize: none;
}

.modal-footer_AddAllergy {
  text-align: center;
  margin-top: 15px;
}

.modal-footer_AddAllergy button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.modal-footer_AddAllergy .save-button_AddAllergy {
  background-color: #003366;
  color: white;
}

.modal-footer_AddAllergy .save-button_AddAllergy:hover {
  background-color: #002244;
}

.modal-footer_AddAllergy .cancel-button_AddAllergy {
  background-color: #e74c3c;
  color: white;
}

.modal-footer_AddAllergy .cancel-button_AddAllergy:hover {
  background-color: #c0392b;
}

h1,
h2 {
  color: #041c32;
  font-weight: 700;
  margin-bottom: 20px;
}

h2 {
  font-size: 18px;
}

.add-button_PatientDocuments {
  background-color: #d1a227;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.add-button_PatientDocuments:hover {
  background-color: #b68c1f;
}

.modal_PatientDocuments {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 600px;
  max-height: 90vh; /* Limit the height to make it scrollable */
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto;
}
.modal_PaymentForm {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-height: 90vh; /* Limit the height to make it scrollable */
  border-radius: 10px;
  padding: 25px;

  z-index: 1000;
  overflow-y: auto;
}

.modal_PatientDocuments.active {
  display: block;
}

.modal_PatientDocuments h2 {
  margin-top: 0;
  font-weight: 700;
  text-align: center;
}

.form-group_PatientDocuments {
  margin-bottom: 10px;
}

.form-group_PatientDocuments label {
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}

.form-group_PatientDocuments input,
.form-group_PatientDocuments select,
.form-group_PatientDocuments textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.modal-footer_PatientDocuments {
  text-align: center;
  margin-top: 15px;
}

.modal-footer_PatientDocuments button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.modal-footer_PatientDocuments .save-button_PatientDocuments {
  background-color: #003366;
  color: white;
}

.modal-footer_PatientDocuments .save-button_PatientDocuments:hover {
  background-color: #002244;
}

.modal-footer_PatientDocuments .cancel-button_PatientDocuments {
  background-color: #e74c3c;
  color: white;
}

.modal-footer_PatientDocuments .cancel-button_PatientDocuments:hover {
  background-color: #c0392b;
}

.action-buttons_PatientDocuments button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.action-buttons_PatientDocuments .view-button_PatientDocuments {
  background-color: #4caf50;
}

.action-buttons_PatientDocuments.view-button_PatientDocuments:hover {
  background-color: #45a049;
}

.action-buttons_PatientDocuments .download-button_PatientDocuments {
  background-color: #2196f3;
}

.action-buttons_PatientDocuments .download-button_PatientDocuments:hover {
  background-color: #1e87e5;
}

.action-buttons_PatientDocuments .edit-button_PatientDocuments {
  background-color: #ffa500;
}

.action-buttons_PatientDocuments .edit-button_PatientDocuments:hover {
  background-color: #e59400;
}
.elabs-status_AddLabOrder {
  text-align: center;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: 500;
}

.tabs-container__two {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 5px;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.tabs-container__three {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 5px;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
}

.tab-button__two {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #011434; /* Dark Blue */
  background-color: #d9d9d9; /* Light Gray */
  border: 2px solid #d9d9d9; /* Light Gray */
  border-radius: 25px;
  cursor: pointer;
  text-transform: capitalize;
  overflow: hidden;
  transition: all 0.3s ease;
}

.tab-button__two:hover {
  border-color: #bc9c22; /* Metallic Gold */
}

.tab-button__two:focus {
  outline: 3px solid #bc9c22; /* Metallic Gold */
  outline-offset: 2px;
}

.active-tab__two {
  color: #fff;
  background-color: #011434; /* Dark Blue */
  border-color: #bc9c22; /* Metallic Gold */
}

.container_NotesTabScreen {
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #011434; /* Dark Blue */
  font-weight: 700;
  text-align: left;
  margin-bottom: 25px;
}

.add-button_NotesTabScreen {
  position: relative;
  display: inline-block;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #011434; /* Dark Blue */
  background-color: #d9d9d9; /* Light Gray */
  border: 2px solid #d9d9d9; /* Light Gray */
  border-radius: 25px;
  cursor: pointer;
  text-transform: capitalize;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-right: 12px;
}

.add-button_NotesTabScreen.active {
  color: #fff;
  background-color: #011434; /* Dark Blue */
  border-color: #bc9c22; /* Metallic Gold */
}

.add-button_NotesTabScreen:hover {
  border-color: #bc9c22; /* Metallic Gold */
}

.form-group_NotesTabScreen {
  margin-bottom: 15px;
}

.form-group_NotesTabScreen label {
  font-weight: 600;
  display: block;
  margin-bottom: 6px;
  color: #333;
}

.form-group_NotesTabScreen input,
.form-group_NotesTabScreen select,
.form-group_NotesTabScreen textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  background-color: #f9f9f9;
}

.form-group_NotesTabScreen input:focus,
.form-group_NotesTabScreen select:focus,
.form-group_NotesTabScreen textarea:focus {
  border-color: #bc9c22; /* Metallic Gold */
  outline: none;
  background-color: #fff;
}

.modal-footer_NotesTabScreen {
  text-align: center;
  margin-top: 20px;
}

.modal-footer_NotesTabScreen button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.3s ease;
}

.modal-footer_NotesTabScreen .save-button_NotesTabScreen {
  background-color: #003366; /* Dark Blue */
  color: #fff;
}

.modal-footer_NotesTabScreen .save-button_NotesTabScreen:hover {
  background-color: #002244;
}

.modal-footer_NotesTabScreen .cancel-button_NotesTabScreen {
  background-color: #e74c3c; /* Red */
  color: #fff;
}

.modal-footer_NotesTabScreen .cancel-button_NotesTabScreen:hover {
  background-color: #c0392b; /* Darker Red */
}

.notes_style table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 25px;
}

.notes_style th,
td {
  padding: 12px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 14px;
}

.notes_style th {
  background-color: #003366; /* Dark Blue */
  color: #fff;
  text-transform: uppercase;
}

.notes_style td {
  background-color: #f9f9f9;
}

.notes_style td:nth-child(odd) {
  background-color: #f4f4f4;
}

.notes_style td button {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
  background-color: #d9d9d9; /* Light Gray */
  color: #011434; /* Dark Blue */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.notes_style td button:hover {
  background-color: #bc9c22; /* Metallic Gold */
  color: #fff;
  transform: scale(1.03);
}

.modal-container_Soap {
  margin: 0 auto;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header_Soap {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #1d3557;
  margin-bottom: 20px;
}

.section_Soap {
  margin-top: 15px;
  background-color: #e0e4ea;
  border-left: 5px solid #1d3557;
  border-radius: 8px;
  padding: 10px;
}

.section-header_Soap {
  font-weight: bold;
  color: #041c32;
  font-size: 18px;
  margin-bottom: 10px;
}

.section-content_Soap {
  padding: 10px 0;
  font-size: 14px;
  color: #333;
}

.button_Soap {
  display: inline-block;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  border-radius: 20px;
  background: #007bff;
  color: white;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-start;
}

.form-row_Vitals {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.form-group_Vitals {
  flex: 1 1 calc(50% - 10px);
  margin-bottom: 10px;
}

.form-group_Vitals label {
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}

.form-group_Vitals input,
.form-group_Vitals select,
.form-group_Vitals textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.form-group_Vitals textarea {
  resize: none;
}
.client-history__buttons {
  text-align: center;
  margin-top: 15px;
  align-self: flex-end;
}
.client-history__button--cancel {
  background-color: #e74c3c;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
.client-history__button--save {
  background-color: #003366;
  color: white;
  margin-left: 10px;
  padding: 10px;
  border-radius: 10px;
}
.modal-footer_Vitals {
  text-align: center;
  margin-top: 15px;
}

.modal-footer_Vitals button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.modal-footer_Vitals .save-button_Vitals {
  background-color: #003366;
  color: white;
}

.modal-footer_Vitals .save-button_Vitals:hover {
  background-color: #002244;
}

.modal-footer_Vitals .cancel-button_Vitals {
  background-color: #e74c3c;
  color: white;
}

.modal-footer_Vitals .cancel-button_Vitals:hover {
  background-color: #c0392b;
}

.vitals-list_Vitals {
  margin-top: 20px;
}

.vitals-list_Vitals table {
  width: 100%;
  border-collapse: collapse;
}

.vitals-list_Vitals th,
.vitals-list_Vitals td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.vitals-list_Vitals th {
  background-color: #003366;
  color: white;
}

.vitals-list_Vitals td {
  background-color: #f9f9f9;
}

/*  */
.eprescribe-status_Medications {
  text-align: center;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: 500;
}

.form-row_Medications {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.form-group_Medications {
  flex: 1 1 calc(50% - 10px);
  margin-bottom: 10px;
}

.form-group_Medications label {
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}

.form-group_Medications input,
.form-group_Medications select,
.form-group_Medications textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.form-group_Medications textarea {
  resize: none;
}

.modal-footer_Medications {
  text-align: center;
  margin-top: 15px;
}

.modal-footer_Medications button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.modal-footer_Medications .save-button_Medications {
  background-color: #003366;
  color: white;
}

.modal-footer_Medications .save-button_Medications:hover {
  background-color: #002244;
}

.modal-footer_Medications .cancel-button_Medications {
  background-color: #e74c3c;
  color: white;
}

.modal-footer_Medications .cancel-button_Medications:hover {
  background-color: #c0392b;
}

.medication-list_Medications {
  margin-top: 20px;
}

.medication-list_Medications table {
  width: 100%;
  border-collapse: collapse;
}

.medication-list_Medications th,
.medication-list_Medications td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.medication-list_Medications th {
  background-color: #003366;
  color: white;
}

.medication-list_Medications td {
  background-color: #f9f9f9;
}

.form-row_ProblemsScreen {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.form-group_ProblemsScreen {
  flex: 1 1 calc(50% - 10px);
  margin-bottom: 10px;
}

.form-group_ProblemsScreen label {
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}

.form-group_ProblemsScreen input,
.form-group_ProblemsScreen select,
.form-group_ProblemsScreen textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.form-group_ProblemsScreen textarea {
  resize: none;
}

.modal-footer_ProblemsScreen {
  text-align: center;
  margin-top: 15px;
}

.modal-footer_ProblemsScreen button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.modal-footer_ProblemsScreen .save-button_ProblemsScreen {
  background-color: #003366;
  color: white;
}

.modal-footer_ProblemsScreen .save-button_ProblemsScreen:hover {
  background-color: #002244;
}

.modal-footer_ProblemsScreen .cancel-button_ProblemsScreen {
  background-color: #e74c3c;
  color: white;
}

.modal-footer_ProblemsScreen .cancel-button_ProblemsScreen:hover {
  background-color: #c0392b;
}

/* Container */
.form-container_AddQuestion {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  /* border: 1px solid red; */
}

/* Title */
.form-title_AddQuestion {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #041c32;
  text-align: left;
}

/* Form */
.form_AddQuestion {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Form Group */
.form-group_AddQuestion {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

/* Labels */
.form-label_AddQuestion {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  color: #333;
}

/* Input & Select */
.form-input_AddQuestion {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Grid for scoring range */
.grid-container_AddQuestion {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

/* Button */
.submit-btn_AddQuestion {
  width: 100%;
  padding: 12px;
  background-color: #041c32;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn_AddQuestion:hover {
  background-color: #062645;
}
.add-question-container {
  max-width: 600px;
  background-color: white;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.add-question-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.add-question-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-form-group {
  display: flex;
  flex-direction: column;
}

.add-form-label {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 5px;
}

.add-form-input,
.add-form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.add-answer-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.add-answer-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-answer-value {
  width: 60px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-remove-answer-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.add-remove-answer-btn:hover {
  background-color: #d43f3f;
}

.add-add-answer-btn {
  background-color: #e0e0e0;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  align-self: flex-start;
  color: #000;
}

.add-add-answer-btn:hover {
  background-color: #c7c7c7;
}

.add-submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #041c32;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
}

.add-submit-btn:hover {
  background-color: #062645;
}

.questionnaire-container {
  max-width: 700px;
  background-color: white;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  border-radius: 8px;
  max-height: 95vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  /* overflow-y: auto; */
}
.forscrolll {
  overflow-y: auto;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}

.questionnaire-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.questionnaire-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.questionnaire-form-group {
  display: flex;
  flex-direction: column;
}

.questionnaire-label {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 5px;
}

.questionnaire-input,
.questionnaire-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.questionnaire-textarea {
  min-height: 100px;
}

.questionnaire-submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #041c32;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.questionnaire-submit-btn:hover {
  background-color: #062645;
}

.questionnaire-list-container {
  background-color: white;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.questionnaire-list-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.questionnaire-list {
  list-style: none;
  padding: 0;
}

.questionnaire-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.questionnaire-delete-icon {
  transition: all 0.1s ease-in-out;
}

.active-icon {
  color: red;
  cursor: pointer;
}

.active-icon:hover {
  color: rgb(255, 100, 100);
}

.disabled-icon {
  color: #f5c6c6;
  cursor: not-allowed;
}

/* QuestionCategoryList Styles */
.question-category-container {
  background-color: white;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.question-category-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.question-category-button-container {
  margin: 16px 0;
}

.question-category-button {
  background-color: #041c32;
  border: 2px solid #041c32;
  color: white;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  align-self: flex-start;
  display: flex;
}

.question-category-button:hover {
  background-color: white;
  color: #041c32;
}

.question-category-list {
  list-style: none;
  padding: 0;
}

.question-category-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.question-category-trash-icon {
  color: red;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.question-category-trash-icon:hover {
  color: rgb(255, 100, 100);
}

.question-category-sublist {
  margin-bottom: 20px;
  padding-left: 40px;
}

.question-category-subitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

/* ErrorFetching Styles */
.error-fetching-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-fetching-message {
  color: #c53030;
  padding: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: #fbd5d5;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.welcome-message {
  background: #e3f2fd;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 25px;
}

.welcome-message h2 {
  color:  #333;
  margin-top: 0;
  font-size: 24px;
}
.success-message {
  background: #e8f5e9;
  border: 1px solid #4caf50;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.success-message h2 {
  color: #4caf50;
  margin-top: 0;
  font-size: 24px;
}

.success-message p {
  font-size: 16px;
  line-height: 1.6;
  color: #2c3e50;
  margin: 15px 0;
}
.welcome-text {
  font-size: 16px;
  line-height: 1.6;
  color: #2c3e50;
  margin: 15px 0;
}
.container_VerifyIdentity {
  width: 100%;

  border-radius: 12px;
  text-align: center;
}
.cta-button {
  display: block;
  width: 100%;
  background-color: #d1a227;
  color: white;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;

  position: relative;
}

.modal_RequiredForms {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-height: 94vh;
  border-radius: 10px;
  padding: 25px;
  z-index: 1000;
  overflow-y: auto;
}

.form-content {
  width: 70%;
  padding: 30px;
  overflow-y: auto;
}
.main-content {
  display: flex;
  width: 100%;
  height: 100%;

  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow: hidden;
  flex-direction: row;
  margin-top: 20px;
}
.sidebar_RequiredForms {
  width: 30%;
  background: #041c32;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
}

.sidebar_RequiredForms h3 {
  color: white;
  margin: 0 0 15px 0;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-card_RequiredForms {
  background: #ffffff;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-card_RequiredForms:hover {
  transform: translateX(5px);
  border-color: #d1a227;
}

.form-card_RequiredForms.active {
  border-color: #d1a227;
  background: #fff8e1;
}

.form-card_RequiredForms.completed {
  background: #e8f5e9;
  border-color: #4caf50;
}

.form-card_RequiredForms h3 {
  margin: 0;
  font-size: 1rem;
  color: #041c32;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkmark_RequiredForms {
  color: #4caf50;
  font-weight: bold;
  font-size: 1.2rem;
}

.sidebar_RequiredForms {
  width: 30%;
  background: #041c32;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
}

.sidebar_RequiredForms h3 {
  color: white;
  margin: 0 0 15px 0;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    height: auto;
  }

  .sidebar_RequiredForms,
  .form-content_RequiredForms {
    width: 100%;
    height: auto;
  }

  .sidebar_RequiredForms {
    padding: 15px;
  }

  .form-card_RequiredForms {
    padding: 12px;
  }
}
.form-card_RequiredForms {
  background: #ffffff;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkmark {
  color: #4caf50;
  font-weight: bold;
  font-size: 1.2rem;
}
.form-card_RequiredForms:hover {
  transform: translateX(5px);
  border-color: #d1a227;
}

.form-card_RequiredForms.active {
  border-color: #d1a227;
  background: #fff8e1;
}

.form-card_RequiredForms.completed {
  background: #e8f5e9;
  border-color: #4caf50;
}

.form-card_RequiredForms h3 {
  margin: 0;
  font-size: 1rem;
  color: #041c32;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
.diagnosis-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  background-color: white;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.diagnosis-item:hover {
  background-color: #f0f8ff;
}

/* Scoped Styles for AgencyConsentForm */
.agency-consent-form {
  font-family: "Roboto", sans-serif;
  justify-content: center;
  align-items: center;
  margin: 0;
  overflow: hidden;
}

.agency-consent-form .modal {
  background-color: #ffffff;
  border-radius: 10px;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.agency-consent-form h1,
.agency-consent-form h3 {
  text-align: center;
  font-weight: 700;
  color: #003366;
  margin-bottom: 20px;
}

.agency-consent-form label {
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  display: block;
}

.agency-consent-form input,
.agency-consent-form select,
.agency-consent-form button {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.agency-consent-form .quill-container {
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

.table-container {
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-container th {
  background-color: #003366;
  color: white;
}

.table-container .toggle-btn {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  background: grey;
}

.table-container .active {
  background-color: green;
  color: white;
}

.table-container .inactive {
  background-color: gray;
  color: white;
}

.table-container .delete-btn {
  background-color: #ff5252;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.table-container .delete-btn:hover {
  background-color: #d42d2d;
}

.table-container .view-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.table-container .view-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: left;
  position: relative;
}

.table-container .close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
}
.editor-container {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 6px;
  font-family: Arial, sans-serif;
  background: #fff;
}

.editor-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.editor-content {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 4px 4px 0 0;
}

.ql-container.ql-snow {
  border: none;
}
.table-container table th,
.table-container table td {
  border: 1px solid #ddd;
  padding: 12px;
}

.view-btn {
  background-color: #007bff;
  color: white;
}
.download-btn {
  background-color: #6c757d;
  color: white;
}
.status-signed {
  color: green;
  font-weight: bold;
}
.status-sent {
  color: blue;
  font-weight: bold;
}
.View_Document {
  font-size: 15px;
  color: black;
  transition: color 0.3s ease;
}

.View_Document:hover {
  background-color: grey;
}

.modal_Consent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  max-width: 1000px;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 90vh; /* Limit the height to make it scrollable */
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto;
}
.modal_Consent_loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 90vh; /* Limit the height to make it scrollable */
  border-radius: 10px;
  padding: 25px;
  z-index: 1000;
  overflow-y: auto;
}
.checkbox_Consent {
  margin: 20px 0;
}
.button_Consent {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1d3557;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button_Consent:hover {
  background-color: #457b9d;
}
.custom-flex {
  display: flex; /* Equivalent to 'flex' */
  padding-top: 20px; /* Equivalent to 'pt-[20px]' */
  justify-content: center; /* Equivalent to 'justify-center' */
  align-items: center; /* Equivalent to 'items-center' */
  column-gap: 8px; /* Equivalent to 'gap-x-2' (8px by default in Tailwind) */
  height: 100%; /* Equivalent to 'h-full' */
}
.custom-button_pluss {
  padding: 2px; /* Equivalent to 'p-[2px]' */
  background-color: #d1fae5; /* Equivalent to 'bg-green-100' */
  width: 1.75rem; /* Equivalent to 'w-7' (28px) */
  height: 1.75rem; /* Equivalent to 'h-7' (28px) */
  border: 1px solid #34d399; /* Equivalent to 'border' and 'border-green-400' */
  border-radius: 4px; /* Equivalent to 'rounded' */
  color: #047857; /* Equivalent to 'text-green-700' */
  transition: background-color 0.2s ease-in-out; /* Equivalent to 'transition-colors' */
}

.custom-button_pluss:hover {
  background-color: #a7f3d0; /* Equivalent to 'hover:bg-green-200' */
}
.custom-button {
  padding: 2px; /* Equivalent to 'p-[2px]' */
  width: 1.75rem; /* Equivalent to 'w-7' (28px) */
  height: 1.75rem; /* Equivalent to 'h-7' (28px) */
  border-width: 1px; /* Equivalent to 'border' */
  border-radius: 0.25rem; /* Equivalent to 'rounded' */
  transition: background-color 0.2s, color 0.2s; /* Equivalent to 'transition-colors' */
}

.bg-gray {
  background-color: #f3f4f6; /* Equivalent to 'bg-gray-100' */
  border-color: #9ca3af; /* Equivalent to 'border-gray-400' */
  color: #9ca3af; /* Equivalent to 'text-gray-400' */
}

.bg-red {
  background-color: #fee2e2; /* Equivalent to 'bg-red-100' */
  border-color: #f87171; /* Equivalent to 'border-red-400' */
  color: #b91c1c; /* Equivalent to 'text-red-700' */
}

.bg-red:hover {
  background-color: #fecaca; /* Equivalent to 'hover:bg-red-200' */
}

.dashboard-container_two {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f6f8;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.alert-box,
.mental-wellness-index,
.treatment-insights,
.key-indicator {
  background: white;
  /* padding: 15px; */
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  text-align: center;
}

.divider_two {
  height: 3px;
  background-color: grey;
  width: 100%;
}

.divider_two.highlight {
  background-color: #b59f46;
}

.section-title_two {
  font-size: 16px;
  font-weight: bold;
  color: #1d2b3a;
  margin: 10px;
}

.section-title_two.highlight {
  color: #b59f46;
}

.divider {
  height: 3px;
  background-color: #b59f46;
  width: 100%;
}

/* background-color: #b59f46; */

.alert-message {
  font-size: 14px;
  color: #1d2b3a;
  font-weight: 800px;
}

.metrics-container {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.mental-wellness-index,
.treatment-insights {
  flex: 1;
  margin: 10px;
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: black;
}

.graph-container {
  margin-top: 10px;
}

.graph {
  width: 100%;
  height: 150px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.send-questionnaire {
  background-color: #12223c;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 20px;
}

.back,
.restart,
.next {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back {
  background-color: #ccc;
}

.restart {
  background-color: #12223c;
  color: white;
}

.next {
  background-color: #ccc;
}
.custom-select_two {
  padding-left: 1rem; /* Equivalent to 'px-4' (16px) */
  padding-right: 1rem; /* Equivalent to 'px-4' (16px) */
  padding-top: 0.5rem; /* Equivalent to 'py-2' (8px) */
  padding-bottom: 0.5rem; /* Equivalent to 'py-2' (8px) */
  background-color: black; /* Replace 'primaryBlue' with your actual primary blue color */
  color: white; /* Equivalent to 'text-white' */
  width: 100%; /* Equivalent to 'w-full' */
  border: none; /* Optional: Tailwind does not add a border by default */
  border-radius: 4px; /* Optional: Adds a slight rounding to match Tailwind's default feel */
}
.custom_KEY_MENTAL_box_style {
  padding: 24px; /* Equivalent to p-6 */
  border-radius: 8px; /* Equivalent to rounded-lg */
  border-top-left-radius: 0; /* Remove top-left radius (rounded-t-none) */
  border-top-right-radius: 0; /* Remove top-right radius (rounded-t-none) */
  background-color: white; /* Equivalent to bg-white */
}
.custom-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); /* Default: 2 columns */
  gap: 0.75rem; /* 12px gap */
  padding: 0.25rem; /* 4px padding */
}

@media (min-width: 768px) {
  /* md breakpoint (≥768px) */
  .custom-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr)); /* 3 columns */
  }
}

@media (min-width: 1024px) {
  /* lg breakpoint (≥1024px) */
  .custom-grid {
    grid-template-columns: repeat(5, minmax(0, 1fr)); /* 5 columns */
  }
}
.custom-div {
  padding: 0.5rem; /* p-2 */
  border-radius: 0.75rem; /* rounded-xl */
  font-size: 0.875rem; /* text-sm */
  color: var(--primaryBlue); /* text-primaryBlue */
  text-align: center; /* text-center */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06); /* shadow */
  border: 1px solid var(--primaryGray); /* border border-primaryGray */
}
.card-content_MemberPlan {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}
.membership-card_MemberPlan {
  background: white;
  width: 400px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  overflow: hidden;
  border: 2px solid #d1a227;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .membership-card_MemberPlan {
    width: 90%;
  }

  .card-content_MemberPlan {
    grid-template-columns: 1fr;
  }
}
.card-header_MemberPlan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.program-name {
  font-size: 22px;
  font-weight: bold;
  color: #2c3e50;
}
.info-group {
  margin-bottom: 10px;
}
.label_MemberPlan {
  font-size: 12px;
  color: #7f8c8d;
  margin-bottom: 3px;
}

.value_MemberPlan {
  font-size: 14px;
  color: #2c3e50;
  font-weight: 600;
}
.membership-status_MemberPlan {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.membership-status_MemberPlan.active {
  background-color: #28a745; /* Green for active */
}

.membership-status_MemberPlan.paused {
  background-color: #ffc107; /* Yellow for paused */
}

.membership-status_MemberPlan.expired {
  background-color: #dc3545; /* Red for expired */
}
.progress-bar_MemberPlan {
  position: relative;
  background: #e0e0e0;
  border-radius: 10px;
  height: 10px;
  margin-top: 5px;
  width: 100%;
}

.progress_MemberPlan {
  position: absolute;
  background: #28a745;
  border-radius: 10px;
  height: 100%;
  transition: width 0.3s ease;
}

.progress-text_MemberPlan {
  font-size: 12px;
  color: #2c3e50;
  margin-top: 5px;
}
.action-buttons_MemberPlan {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.action-buttons_MemberPlan button {
  background-color: #d1a227;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.action-buttons_MemberPlan button:hover {
  background-color: #b8931f;
}
.card-footer_MemberPlan {
  background: #d1a227;
  padding: 10px 20px;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 10px;
}

.card-footer_MemberPlan a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.card-number_MemberPlan {
  letter-spacing: 2px;
}

.container-custom {
  width: 100%;
  border-radius: 10px;
}

.card-custom {
  /* box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); */
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
}

.card-header-custom {
  border-bottom: 1px solid #ddd;
  background-color: #f8f9fa;
}

.card-header-custom h4 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: #bc9c22;
  background-color: #011434;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0.4rem;
}

.card-body-custom {
  padding: 1rem;
}

.table-responsive-custom {
  overflow-x: auto;
}

.table-custom {
  width: 100%;
  border-collapse: collapse;
}

.table-custom th,
.table-custom td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #ddd;
}

.table-custom th {
  background-color: #D9D9D9;
  font-weight: bold;
  color: black;
  font-size: 20px;
}

.table-custom td {
  background-color: #fff;
  font-weight: bold;
  font-size: 17px;
}

.action-links a {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.action-links a:hover {
  text-decoration: underline;
}

.no-print {
  display: inline-block;
  cursor: pointer;
  color: #007bff;
}

.no-print:hover {
  text-decoration: underline;
}

/* Modal Background */
/* Modal Background */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

/* Modal Dialog */
.custom-modal-dialog {
  height: 90%;
  width: 90%;
  max-width: 800px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

/* Modal Content */
.custom-modal-content {
  display: flex;
  flex-direction: column;
}

/* Modal Header */
.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

/* Modal Title */
.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

/* Close Button */
.custom-btn-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

/* Modal Body */
.custom-modal-body {
  padding: 20px;
  height: 620px;
  overflow-y: auto;
}
/* Modal Body */
.custom-modal-body {
  padding: 20px;
  background-color: #f8f9fa;
}

/* Card */
.custom-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #ddd;
  margin-top: 10px;
}

/* Card Header */
.custom-card-header {
  background: #007bff;
  color: #fff;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: bold;
}

/* Card Title */
.custom-card-title {
  margin: 0;
}

/* Card Body */
.custom-card-body {
  padding: 15px;
}

/* Table */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
}

/* Table Borders */
.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

/* Table Header */
.custom-table th {
  background: #f1f1f1;
  font-weight: bold;
  width: 25%;
}

/* Responsive Table */
@media (max-width: 600px) {
  .custom-table th,
  .custom-table td {
    display: block;
    width: 100%;
  }

  .custom-table th {
    background: #007bff;
    color: #fff;
  }
}
.card-header_two {
  background-color:  #011434 !important;
  color:  #BC9C22 !important;
  text-align: left;
  padding: 10px;
}
/*  */
.accordion-body{
  display: flex;
  flex-direction: column;
align-items: flex-start;
}

/* Modal Footer */
.modal-footer_two {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
}

/* Button Styles */
.btn_close_print {
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.btn_close_print-secondary {
  background-color: #6c757d;
  color: #fff;
}

.btn_close_print-secondary:hover {
  background-color:  #9e7f1f;
}

/* No Print Class */
.no-print {
  display: inline-block;
}

/* Print Function */
@media print {
  .no-print {
    display: none;
  }
}
.Eligibilitytable th {
  background-color:   #D9D9D9;
  color: black;
}
.modal-content__two {
  background-color: #fff;
  /* margin: 5% auto; */
  padding: 20px;
  border-radius: 8px;
  max-width: 600px; 
  position: relative;
}

.close_consent_modal {
  background: none;
  border: none;
  color: #aaa;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}
.close_consent_modal:hover {
  color: #000;
}
.signature-block {
  margin-top: 15px;
  background: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.signature-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}
.signature-status {
  margin-top: 4px;
  font-style: italic;
  color: #333;
  white-space: pre-line;
}
.sign-btn {
  background-color: #007bff;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
.tabcontent {
  background-color: #e0e4ea;
  padding: 20px;
  border-radius: 8px;

  margin: auto;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 15px;
}

.radio-group,
.checkbox-group {
  margin-bottom: 15px;
}

.radio-options label,
.checkbox-options label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.events-container {
  margin-top: 15px;
}

.event-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.select-box {
  flex: 1;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.date-picker {
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.delete-btn_two {
  padding: 3px 6px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid black;
  color: black;
}

.add-event {
  /* background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer; */
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid black;
  color: black;
}

.submit-payment {
  background-color: #1c2b4d;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
}

.card-header_two-custom{
  padding: 10px;
  background-color: #011434;
  text-align: left;
  color: #BC9C22;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold;
}

/* ajay */
.container_CalendarView {
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
/* Header & Navigation */
.header-bar_CalendarView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 12px 20px;
  border-bottom: 1px solid  #E0E0E0;
  border-radius: 6px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.header-left_CalendarView {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.view-toggle_CalendarView button,
.nav-button_CalendarView,
.today-button_CalendarView {
  padding: 8px 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  background:  #E0E0E0;
  color:  #333;
}
.view-toggle button:hover { background: #0D6EFD; color: #fff; }
.nav-button_CalendarView { background:  #E0E0E0; }
.nav-button_CalendarView:hover { background: #0D6EFD; color: #fff; }
.today-button_CalendarView { background:  #E0E0E0; }
.today-button_CalendarView:hover { background: #0D6EFD; color: #fff; }
.agenda-button_CalendarView {
  padding: 8px 14px;
  border: none;
  border-radius: 4px;
  background: #0D6EFD;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s;
}
.agenda-button_CalendarView:hover { background:  #0b5ed7; }
.add-new-button_CalendarView,
.synch-button_CalendarView,
.settings-button_CalendarView {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.add-new-button_CalendarView { background: #0D6EFD; }
.add-new-button_CalendarView:hover { transform: translateY(-2px); box-shadow: 0 4px 8px rgba(0,0,0,0.15); }
.synch-button_CalendarView { background: #0D6EFD; }
.synch-button_CalendarView:hover { transform: translateY(-2px); box-shadow: 0 4px 8px rgba(0,0,0,0.15); }
.settings-button_CalendarView { background: #0D6EFD; }
.settings-button_CalendarView:hover { transform: translateY(-2px); box-shadow: 0 4px 8px rgba(0,0,0,0.15); }
.confirm-btn_CalendarView, .decline-btn_CalendarView {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.confirm-btn_CalendarView { background: #0D6EFD; color: #fff; }
.confirm-btn_CalendarView:hover { transform: translateY(-2px); box-shadow: 0 4px 8px rgba(0,0,0,0.15); }
.decline-btn_CalendarView { background:  #333; color: #fff; }
.decline-btn_CalendarView:hover { transform: translateY(-2px); box-shadow: 0 4px 8px rgba(0,0,0,0.15); }
.date-range_CalendarView { font-weight: 600; margin-left: 10px; }
#search-container_CalendarView { margin-bottom: 10px; }
#search-input_CalendarView {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid  #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
}
.thumb_CalendarView {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}
/* Calendar Containers */
#calendar-container_CalendarView {
  border: none;
  background: #fff;
  height: 400px;
  padding: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
  position: relative;
  border-radius: 4px;
}
.day-view-container_CalendarView,
.week-view-container_CalendarView,
.month-view-container_CalendarView {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
}
.day-view-container_CalendarView { display: grid; grid-template-columns: 60px 1fr; }
.week-view-container_CalendarView { display: grid; grid-template-columns: 60px repeat(7, 1fr); }
.time-labels_CalendarView {
  background:  #E0E0E0;
  border-right: 1px solid  #E0E0E0;
  overflow-y: auto;
}
.time-label_CalendarView {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid  #E0E0E0;
  font-size: 0.9rem;
}
.day-column-slots_CalendarView {
  position: relative;
  overflow-y: auto;
  border-right: 1px solid  #E0E0E0;
}
.day-header_CalendarView {
  text-align: center;
  background: #fff;
  font-weight: 600;
  border-bottom: 1px solid  #E0E0E0;
  padding: 5px 0;
  position: sticky;
  top: 0;
  z-index: 1;
}
.time-slot_CalendarView {
  height: 30px;
  border-bottom: 1px dashed  #E0E0E0;
  position: relative;
  cursor: pointer;
}
.time-slot_CalendarView:hover { background: #f1f1f1; }
.appointment-block_CalendarView {
  background: #0D6EFD;
  border: none;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.85rem;
  color: #fff;
  cursor: pointer;
  position: absolute;
  left: 5px;
  right: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
}
.appointment-time_CalendarView { font-weight: 600; }
.appointment-details_CalendarView {
  font-size: 0.75rem;
  line-height: 1.3;
}
.appointment-icons_CalendarView span {
  cursor: pointer;
  padding: 2px 5px;
  background:  #E0E0E0;
  border-radius: 3px;
  margin-right: 5px;
  font-size: 0.9em;
}
.appointment-icons_CalendarView span:hover { background: #0D6EFD; color: #fff; }
.timer {
  display: block;
  font-size: 0.8em;
  color: #0D6EFD;
  margin-top: 5px;
}
/* Status badge for quick status identification */
.status-badge_CalendarView {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 2px 6px;
  font-size: 0.7rem;
  border-radius: 4px;
  background: rgba(0,0,0,0.3);
  color: #fff;
}
.month-grid_CalendarView {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}
.month-cell_CalendarView {
  background: #fff;
  border: none;
  min-height: 100px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}
.month-cell-header_CalendarView {
  font-size: 0.9em;
  font-weight: 600;
}
.month-appointment_CalendarView {
  font-size: 0.7em;
  background: #0D6EFD;
  margin-top: 2px;
  padding: 2px;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
}
.appointments-table-container_CalendarView {
  background: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.appointments-table_CalendarView {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
.appointments-table_CalendarView th,
.appointments-table_CalendarView td {
  border: 1px solid  #E0E0E0;
  padding: 8px;
  font-size: 0.85rem;
  text-align: left;
}
.appointments-table_CalendarView th { background:  #E0E0E0;color: #000; }
.clickable {
  color: #0D6EFD;
  text-decoration: none;
  cursor: pointer;
}
.clickable_CalendarView:hover { text-decoration: underline; }
.action-select_CalendarView, .status-select_CalendarView {
  padding: 4px;
  font-size: 0.8rem;
}
.toast_CalendarView {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #0D6EFD;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
  z-index: 2000;
}
.agenda-view-container_CalendarView {
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.agenda-date-group_CalendarView {
  margin-bottom: 15px;
  padding: 10px;
  background:  #E0E0E0;
  border-radius: 4px;
}
.agenda-date-group_CalendarView h3 {
  margin-top: 0;
  font-size: 1.1rem;
  font-weight: 600;
}
.agenda-item_CalendarView {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background: var(--background-color);
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid  #E0E0E0;
}
.agenda-item_CalendarView h4 { margin: 0 0 5px; font-size: 1.1rem; }
.agenda-time_CalendarView { font-size: 1.1rem; margin-bottom: 5px; }
.agenda-details_CalendarView { font-size: 0.9rem; }
.agenda-timer_CalendarView {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.05);
  padding: 6px 10px;
  border-radius: 4px;
  color:  #333;
}
.join-telehealth-btn_CalendarView {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background: #0D6EFD;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s;
}
.join-telehealth-btn_CalendarView:hover {
  background:  #0b5ed7;
}
/* NEW: Clock Button Style */
.clock-btn_CalendarView {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background: #0D6EFD;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  transition: background 0.3s;
}
.clock-btn_CalendarView:hover {
  background:  #0b5ed7;
}
.appointment-tooltip_CalendarView {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  z-index: 2000;
  background: #fff;
  border: 1px solid  #E0E0E0;
  border-radius: 4px;
  padding: 16px;
  width: 320px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  font-family: 'Roboto', sans-serif;
}
.appointment-tooltip_CalendarView.show {
  opacity: 1;
  visibility: visible;
}
.tooltip-content_CalendarView {
  display: flex;
  flex-direction: column;
}
.tooltip-time-status_CalendarView {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 8px;
}
.confirmation-badge_CalendarView {
  background: #0D6EFD;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
}
.tooltip-patient-info_CalendarView { margin-bottom: 8px; }
.tooltip-patient-name_CalendarView { font-weight: 600; font-size: 1.1rem; }
.tooltip-patient-dob_CalendarView { font-size: 0.9rem; color:  #333; }
.tooltip-content_CalendarView hr {
  border: none;
  border-top: 1px solid  #E0E0E0;
  margin: 10px 0;
}
.tooltip-sections_CalendarView {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tooltip-section_CalendarView h4 {
  margin: 0 0 8px;
  font-size: 1rem;
  color:  #333;
}
.tooltip-section_CalendarView div {
  margin-bottom: 4px;
  font-size: 0.9rem;
}
.tooltip-section_CalendarView .patient-intake { display: flex; align-items: center; }
.tooltip-section_CalendarView .patient-intake button {
  margin-left: 10px;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  background:  #E0E0E0;
  cursor: pointer;
  transition: background 0.2s;
}
.tooltip-section_CalendarView .patient-intake_CalendarView button:hover { background: #0D6EFD; color: #fff; }
.dropdown_CalendarView { position: relative; display: inline-block; margin-right: 8px; }
.dropdown-btn_CalendarView {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background:  #E0E0E0;
  color:  #333;
  cursor: pointer;
  transition: background 0.3s;
}
.dropdown-btn_CalendarView:hover { background: #0D6EFD; color: #fff; }
.dropdown-content_CalendarView {
  display: none;
  position: absolute;
  background: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.15);
  z-index: 100;
  border: 1px solid  #E0E0E0;
  border-radius: 4px;
}
.dropdown-content_CalendarView a {
  color:  #333;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
}
.dropdown-content_CalendarView a:hover { background: #0D6EFD; color: #fff; }
.dropdown_CalendarView:hover .dropdown-content_CalendarView { display: block; }
.separator_CalendarView { margin: 10px 0; border: none; border-top: 1px solid  #E0E0E0; }
.mini-calendar-container_CalendarView {
  background: #fff;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 290px;
}
.mini-calendar-container_CalendarView table { width: 100%; border-collapse: collapse; }
.mini-calendar-container_CalendarView th {
  background:  #E0E0E0;
  text-align: center;
  padding: 5px;
  color: black;
}
.mini-calendar-container_CalendarView td {
  width: 14.28%;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  border: 1px solid  #E0E0E0;
}
.mini-calendar-container_CalendarView td:hover { background: #0D6EFD; color: #fff; }
.mini-calendar-container_CalendarView .today_CalendarView { background: #0D6EFD; color: #fff; font-weight: 600; }
.mini-calendar-container_CalendarView .calendar-filters_CalendarView {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.mini-calendar-container_CalendarView .calendar-filters_CalendarView label { font-weight: 500; }
.mini-calendar-container_CalendarView .calendar-filters_CalendarView input {
  padding: 6px;
  border: 1px solid  #E0E0E0;
  border-radius: 4px;
}
.selectedMiniDay_CalendarView { margin-top: 1rem; font-weight: 600; }
.miniEventList_CalendarView { margin-top: 0.5rem; padding-left: 1rem; list-style: circle; }
.appointment-panel_CalendarView {
  position: fixed;
  top: 0;
  right: 0;
  width: 35%; 
  height: 100%;
  background: #fff;
  box-shadow: -3px 0 10px rgba(0,0,0,0.15);
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}
.appointment-panel_CalendarView.show { transform: translateX(0); }
.appointment-panel_CalendarView .modal-content_CalendarView {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  background: #fff;
  border: none;
}
.appointment-panel_CalendarView .modal-content_CalendarView .close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  color:  #333;
  font-size: 24px;
  cursor: pointer;
  border: none;
}
.form-container_CalendarView { width: 100%; max-width: 100%; padding: 0; background: #fff; }
.form-container_CalendarView h2 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color:  #333;
  margin-bottom: 20px;
}
.section_CalendarView {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px;
  background: var(--background-color);
  border-radius: 4px;
  border-left: 4px solid  #333;
}
.section-header_CalendarView {
  margin: 0 0 10px;
  font-weight: 600;
  color:  #333;
  font-size: 18px;
}
.constraints-section_CalendarView .form-row_CalendarView {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 15px;
}
.form-row_CalendarView.full-width_CalendarView { grid-template-columns: 1fr; }
.form-group_CalendarView {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.form-group_CalendarView label {
  font-weight: 600;
  margin-bottom: 5px;
  color:  #333;
}
.form-group_CalendarView input[type="text"],
.form-group_CalendarView input[type="date"],
.form-group_CalendarView input[type="time"],
.form-group_CalendarView select,
.form-group_CalendarView input[type="number"] {
  width: 100%;
  padding: 8px 12px;
  margin-top: 5px;
  border: 1px solid  #E0E0E0;
  border-radius: 4px;
  font-size: 14px;
  background: #fff;
}
.form-group_CalendarView input[disabled] {
  background: #e9ecef;
  cursor: not-allowed;
}
.error-message_CalendarView {
  color: #e74c3c;
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 10px;
  display: none;
}
.button-container_CalendarView {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.multiselect_CalendarView {
  position: relative;
  display: inline-block;
  width: 100%;
}
.checkboxes_CalendarView {
  display: none;
  border: 1px solid  #E0E0E0;
  background: #fff;
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}
.checkboxes_CalendarView label {
  display: block;
  padding: 10px;
  cursor: pointer;
}
.checkboxes_CalendarView label:hover { background: var(--background-color); }
.tag-container_CalendarView {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  border-radius: 4px;
  background: #fff;
  cursor: text;
  align-items: center;
  min-height: 40px;
  border: 1px solid  #E0E0E0;
}
.tag_CalendarView {
  background: #0D6EFD;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.tag-close_CalendarView { margin-left: 8px; cursor: pointer; font-weight: 600; }
.repeat-days-container_CalendarView { display: flex; gap: 10px; margin-top: 10px; }
.day-pill_CalendarView { position: relative; display: inline-block; }
.day-pill_CalendarView input[type="checkbox"] { display: none; }
.day-pill_CalendarView span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background:  #E0E0E0;
  color:  #333;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-weight: 600;
}
.day-pill_CalendarView input[type="checkbox"]:checked + span {
  background: #0D6EFD;
  color: #fff;
}
.day-pill_CalendarView span:hover { background: #0D6EFD; color: #fff; }
.modal_CalendarView {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 600px;
  max-height: 90vh; 
  border-radius: 10px;

  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto; */
}
.close_CalendarView{
/* background-color: red; */
display: flex;
cursor: pointer;
justify-content: flex-end;
margin-bottom: 17px;
}
.modal-content_CalendarView {
  background: #fff;
  padding: 20px 30px;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  margin: 5% auto;
  width: 90%;
  max-width: 800px;
  position: relative;
  border: 2px solid  #333;
}
.modal-content_CalendarView h2 {
  background:  #333;
  color: #fff;
  margin: -20px -30px 20px;
  padding: 15px 30px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 24px;
  text-align: center;
}
.modal-content_CalendarView .close {
  position: absolute;
  top: 15px;
  right: 20px;
  color: #fff;
  background:  #333;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.settings-modal_CalendarView .modal-content_CalendarView {
  width: 90% !important;
  max-width: 800px !important;
  margin: 5% auto !important;
  padding: 20px !important;
  box-sizing: border-box !important;
  border: 2px solid  #333 !important;
}
.settings-modal_CalendarView .modal-content_CalendarView h2 {
  margin: 0 !important;
  padding: 15px !important;
  background:  #333 !important;
  color: #fff !important;
  font-size: 24px !important;
  text-align: center !important;
}
.settings-modal_CalendarView .modal-content_CalendarView .close {
  top: 15px !important;
  right: 20px !important;
  background:  #333 !important;
  color: #fff !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  text-align: center !important;
  line-height: 30px !important;
  cursor: pointer !important;
}
.constraints-section_CalendarView  {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 20px !important;
  margin-bottom: 15px !important;
}
.form-row_CalendarView {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 20px !important;
  margin-bottom: 15px !important;
}
.constraints-section_CalendarView .form-group_CalendarView {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
}
.settings-modal_CalendarView .button-container_CalendarView {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 10px !important;
  margin-top: 20px !important;
}
/* NEW: CSS for Agenda Tabs */
.agenda-tabs_CalendarView {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.agenda-tab_CalendarView {
  padding: 8px 16px;
  border: none;
  background:  #E0E0E0;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  transition: background 0.3s;
  color: #000;
}
.agenda-tab_CalendarView.active {
  background: #0D6EFD;
  color: #fff;
}
/* NEW: Style for buttons inside the Settings modal */

/* Layout fixes for the day rows in Settings modal */



/* Pagination Controls */
.pagination-controls_CalendarView {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}
.pagination-controls_CalendarView button {
  padding: 5px 10px;
  border: 1px solid  #E0E0E0;
  background:  #E0E0E0;
  border-radius: 4px;
  cursor: pointer;
}
.pagination-controls_CalendarView button.active {
  background: #0D6EFD;
  color: #fff;
}
/*  */
.day-row_CalendarView {
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  background: #FFFFFF;
}

.day-row-header_CalendarView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
}

.time-blocks_CalendarView {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.time-block_CalendarView {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  /* border: 1px solid #E5E7EB; */
  /* background: #F9FAFB; */
}

.time-block_CalendarView label {
  font-size: 14px;
  font-weight: 800;
}

.time-block_CalendarView input[type="time"],
.time-block_CalendarView input{
  height: 30px;
  margin-bottom: 5px;
}
.time-block_CalendarView select {
  padding: 6px;
  border: 1px solid #CBD5E1;
  border-radius: 4px;
  font-size: 14px;
}

.add-block-btn_CalendarView {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #0D6EFD;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.add-block-btn_CalendarView:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.remove-block-btn_CalendarView {
  padding: 3px 6px;
  border: none;
  border-radius: 4px;
  color: #000;
  border: 1px solid black;
  cursor: pointer;
  font-size: 13px;
}


.save-settings-button_CalendarView  {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: #0D6EFD;
  color: #fff;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.save-settings-button_CalendarView :hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}
.day-view_CalendarView {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 6px;
  overflow: hidden;
}

.day-view-header_CalendarView {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #E0E0E0;
  background: #F9FAFB;
}

.day-view-grid_CalendarView {
  display: grid;
  grid-template-columns: 80px 1fr;
  border-top: 1px solid #E0E0E0;
}

.day-view-time_CalendarView {
  background: #E5E7EB;
  text-align: center;
  font-size: 14px;
  padding: 10px 0;
  border-right: 1px solid #D1D5DB;
}

.day-view-rows_CalendarView {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #E0E0E0;
}

.day-view-row_CalendarView {
  height: 40px;
  border-bottom: 1px dotted #D1D5DB;
}
.month-view_CalendarView {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
}

.month-view-header_CalendarView {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background: #e0e0e0;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.month-view-grid_CalendarView {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.month-view-day_CalendarView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border: 1px solid #e0e0e0;
  font-size: 16px;
}

.current-month {
  background: #f9f9f9;
}
.month-view_CalendarView {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
}

.month-view-header_CalendarView {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background: #e0e0e0;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.month-view-grid_CalendarView {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.month-view-day_CalendarView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border: 1px solid #e0e0e0;
  font-size: 16px;
}

.current-month {
  background: #f9f9f9;
}

.empty {
  background: #f1f1f1;
}

.week-view_CalendarView {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
}

.week-view-header_CalendarView {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.week-view-grid_CalendarView {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
}

.week-hour-row_CalendarView {
  display: grid;
  grid-template-columns: 100px repeat(7, 1fr);
  border-bottom: 1px solid #e0e0e0;
}

.week-time-cell_CalendarView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #e0e0e0;
  font-weight: bold;
}

.week-day-cell_CalendarView {
  display: flex;  
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
}