.container {
    max-width: 800px;
    margin: auto;
    background-color: #f7f7f7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}

h2 {
    color: #1d3557;
    font-size: 24px;
}

.description {
    color: #333;
    margin-bottom: 20px;
}

.button {
    display: inline-block;
    background-color: #6772e5;
    color: #fff;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 8px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #fff;
}